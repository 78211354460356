.general-tab {
  background-color: #fff;
  margin: -24px;
  margin-top: -16px;
  padding: 16px;
  margin-bottom: 300px;

  h4 {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
