.react-tel-input .country-list {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: none;
  width: 327px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.settings_countryCode_phone_wrapper__fromInvitation
  > .react-tel-input
  .country-list {
  background-color: #fff;
  left: -5px;
}
.settings_countryCode_phone_wrapper_displayNone {
  .selected-flag.open,
  input.form-control.open {
    display: none;
  }
}

.react-tel-input .country-list .country {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // background-color: rgb(242, 242, 242);
  background-color: #fff;
  border-radius: 10px;
  margin: 5px 0;
  padding-left: 17px;
}

.react-tel-input .country-list .country:hover {
  background-color: #f0f2f5;
}

.react-tel-input .country-list .country:focus {
  background-color: #f0f2f5;
}

.react-tel-input .country-list .country-name {
  margin-right: auto;
  margin-left: 6px;
  font-family: "greycliffcf-medium-salt";
  font-size: 13px;
}

.react-tel-input .country-list .dial-code {
  font-family: "greycliffcf-medium-salt";
  font-size: 13px;
  color: #a9b0b9;
}

.react-tel-input .settings {
  &_countryCode {
    &__codeInput {
      &_onlyNumber {
        padding-left: 15px !important;
      }
    }
  }
}

.settings {
  &_countryCode {
    &__codeInput {
      display: none;
      &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        right: 21px;
        z-index: 2;
        cursor: pointer;
        & > div > svg > path {
          fill: #3e3e3e;
        }
      }
    }
    &_heading {
      font-family: "greycliffcf-bold-salt";
      font-size: 20px;
      line-height: 1.05;
      letter-spacing: -0.4px;
      color: #222222;
    }
    &_wrapper {
      position: fixed;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      max-height: 564px;
      width: 344px;
      height: 564px;
      background-color: #ffffff;
      z-index: 3;
      border-radius: 8px;
      padding: 20px 17px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    }
    &_background {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      overflow-x: hidden;
    }
    &_phone {
      &_wrapper {
        position: relative;
        z-index: 3;
        &__fromInvitation {
          padding: 5px;
          border-radius: 8px;
          border: solid 1px #707070;
          // margin-top: 4px;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          & > .react-tel-input {
            width: 100%;
            & > .undefined {
              width: 100%;
              & > .selected-flag {
                width: 100%;
              }
            }
          }
        }
        &__label {
          font-family: "greycliffcf-demibold-salt";
          font-size: 15px;
          line-height: 1.2;
          letter-spacing: normal;
          color: #000000;
          margin-top: 15px;
          margin-bottom: 4px;
          text-transform: capitalize;
        }
        &__valueState {
          font-family: "greycliffcf-demibold-salt";
          font-size: 15px;
          line-height: 1.2;
          letter-spacing: normal;
          color: #000000;
          text-transform: capitalize;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 45px;
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }
}

.phoneInput {
  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 32px;
    margin: 22px 0 0;
    padding-left: 12px;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;

    &--error {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 32px;
      margin: 22px 0 0;
      padding-left: 12px;
      border-radius: 16px;
      background-color: #fff;
      box-sizing: border-box;
      border: solid 1px #f52d22;
    }
  }
}
