.invitees {
  width: 348px;
  max-width: 23vw;
  border-radius: 8px;
  background-color: transparent;
  position: absolute;
  top: 0;
  // left: 620px;
  // right: -24vw;
  // right: calc((100vw - 608px)/2 + 624px);
  box-sizing: border-box;
  // padding-bottom: 5px;

  &__wrapper {
    // padding-top: 4px;
    border-radius: 8px;
    overflow: hidden;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  &__header {
    // padding: 0 16px;
    // padding: 12px 16px;
    padding-bottom: 12px;
    height: 20px;
    line-height: 21px;
    //background-color: #f0f0f0;
    color: #222222;
    letter-spacing: -0.34px;
    font-size: 17px;
    font-family: "greycliffcf-bold-salt";
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__action {
    box-sizing: border-box;
    background-color: white;
    height: 32px;
    border-radius: 25px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__actions {
    padding: 10px 11px;
    display: flex;
    background-color: #f0f2f5;
    // background-color: #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__icon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  &__text {
    font-family: "greycliff-cf-medium";
    font-size: 13px;
    color: #222222;
    letter-spacing: -0.26px;
  }
}

.link__button {
  font-family: "greycliffcf-demibold-salt";
  font-size: 17;
  letter-spacing: "-0.34px";
  color: "#212121";

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.invitees__action:not(:first-child) {
  margin-left: 8px;
}

.add__invitee__on__teeup__creation {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  border-radius: 25px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border: none;
  // border: 2px solid
  padding: 8px 12px;
  cursor: pointer;
  // display: flex;
}

.invitees__content {
  max-height: calc(100vh - 185px);
  overflow: auto;
}

.invitees__content::-webkit-scrollbar {
  width: 5px;
  color: #ffffff;
}

.invitees__content::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #b2b2b2;
}
