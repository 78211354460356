.teeup {
  &__list {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    div {
      div::-webkit-scrollbar {
        display: none;
      }
    }

    &_shorted {
      height: 89%;

      @media (max-height: 850px) {
        height: 85%;
      }
    }
  }
}

.notifications {
  &__wrapper {
    margin-left: 17px;
    position: relative;
    cursor: pointer;
    width: 32px;
    height: 32px;

    &--active {
      border-radius: 8px;
      background-color: #e3e5e8;
    }

    &:hover {
      border-radius: 8px;
      background-color: #e3e5e8;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__count {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f42862;
    font-size: 10px;
    line-height: 15px;
    color: white;
    font-family: "greycliffcf-extrabold-salt";
    top: 0;
    right: 0;
    z-index: 1;
    text-align: center;
  }
}

.scrollbar {
  height: calc(100vh - 60px);
  width: 16px;
  overflow-y: hidden;
  background-color: #f0f2f5;
  position: fixed;

  right: 0;
  top: 60px;
  display: flex;
  justify-content: center;
}

.scroll__thumb {
  width: 7px;
  background-color: darkgrey;
  position: relative;
  border-radius: 8px;
}

.scrollable__container {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.scrollable__container::-webkit-scrollbar {
  width: 0;
}

.teeup__list-container {
  min-height: 62px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  width: 660px;
  max-width: 660px;
  box-sizing: border-box;
  background-color: #f0f2f5;
  padding: 0 16px;
  margin: 0 auto;
}

.teeup__list--comingUp--header {
  display: block;
  margin: 20px 12px 20px 0;

  &__text {
    margin: 0 0 13px 0;
    font-family: "greycliff-cf-bold";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
  }
}

.teeups__item {
  width: 640px;
  margin: 10px 0;
  padding: 0 10px;
}

.teeup__commingup {
  width: calc(656px + 252px);
}

.teeup__list_shorted {
  height: 100%;
}

.teeup__list_shorted .teeups__item {
  width: 640px;
}

.teeup-list-wrapper {
  width: 665px;
  //62px is a height of sticky teeups filters
  height: calc(100vh - 62px);
  padding: 0 10px;
  margin: 0 auto;
}
