.modal-participant-head {
  padding-top: 32px;
  padding-bottom: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--lightGray6);
  margin: 0 -16px -16px -16px;
}

.modal-action-buttons {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}

.modal-participant-info {
  text-align: center;
  div:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
  }

  div:nth-child(2) {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: var(--gray4);
    margin-top: 4px;
  }
}

.modal-participant-head-rounded {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
