.invite-phone-search {
  border: 1px solid var(--gray3);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 2px 5px;
  z-index: 3;

  .form-control {
    width: calc(100% - 46px);
    height: 38px !important;
    padding-left: 45px !important;
    margin-left: 50px !important;

    &:focus {
      border: 1px solid var(--gray4) !important;
      box-shadow: var(--focusShadow);
    }
  }
}

.invite-phone-search__button {
  border-right: 1px solid var(--gray3) !important;
  position: relative;
  padding-right: 10px !important;
  &::before {
    content: url("../../../assets/images/arrow-2-black.svg");
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    z-index: 9;
  }
  &::after {
    content: url("../../../assets/images/black-search.svg");
    position: absolute;
    top: 55%;
    left: calc(100% + 15px);
    transform: translateY(-50%);
  }
}
