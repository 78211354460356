.quick-select {
  .quick-select__option {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e5e8;
    border-radius: 16px;
    font-family: "greycliffcf-medium-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: -0.26px;
    color: #595f82;
    padding: 0 16px;
    width: fit-content;
    margin-bottom: 4px;
    cursor: pointer;

    &.selected {
      color: white;
      background-color: #595f82;
      border-color: #595f82;
    }

    &:hover {
      border-color: #595f82;
    }
  }
}
