* {
  margin: 0;
  padding: 0;
}
.viewteeups_header {
  height: 24px;
  margin: 0;
  font-family: "greycliffcf-bold-salt";
  font-size: 20px;
  line-height: 1.05;
  letter-spacing: -0.4px;
  align-self: baseline;
  color: #222222;
}

.viewteeups__container {
  width: 100%;
  margin: auto;
}

.viewteeups__wrapper {
  height: calc(100vh - 220px);
  overflow: auto;
}

.viewteeups__wrapper::-webkit-scrollbar {
  width: 0;
}

.searchbar__container {
  width: 100%;
  height: 68px;
  margin: 12px 0 16px 0;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
}

.searchbar__text {
  display: flex;
  align-items: center;
}

.searchbar__wrapper {
  width: 576px;
  max-width: 576px;
  height: 38px;
  max-height: 38px;
  background: #f5f8fa;
  // background: #f5f5f5;
  border-radius: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;

  input {
    height: 20px;
    margin: 0 7.9px 0 0;
    font-family: "greycliff-cf-medium";
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.34px;
    text-align: left;
    color: #9d9d9d;
    border: none;
    outline: none;
    background: transparent;
  }

  .searchbar__searchIcon {
    width: 16px;
    margin: 2 0 2;
  }
}

.teeupItem__container {
  width: 100%;
  min-height: 144px;
  margin: 0 0 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;

  .teeupItem_info__container {
    position: relative;
    min-height: 88px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .teeupItem__image {
      width: 135px;
      height: 88px;
      margin: 0 2px 0 0;
    }

    .teeupItem_info {
      margin: 16px 100px 17px 18px;
      flex: 1;

      .teeupItem_name {
        font-family: "greycliffcf-bold-salt";
        font-size: 17px;
        line-height: 1.29;
        letter-spacing: -0.4px;
        text-align: left;
        color: #222222;
        word-break: break-word;
      }

      p {
        font-family: "greycliffcf-demibold-salt";
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: -0.26px;
        text-align: left;
        color: #9d9d9d;
      }
    }

    .teeupItem_time {
      position: absolute;
      top: 16px;
      right: 10px;
      float: right;
      // margin: 16px 10px 0 auto;
      font-family: "greycliff-cf-medium";
      font-size: 12px;
      font-weight: 500;
      line-height: 1.08;
      letter-spacing: -0.22px;
      text-align: right;
      color: #767676;
    }
  }

  .teeupItem__userlist {
    height: 56px;
    max-height: 56px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    position: relative;
    z-index: 0;

    .teeupItem_userImage {
      width: 24px;
      height: 24px;
      margin-left: 6px;
      border: solid 1px #ffffff;
      border-radius: 50%;
    }

    .teeupItem_userImage:first-child {
      margin-left: 16px;
    }

    .teeupItem_displayUsersArrow {
      margin: 24px 21px 24px auto;
      width: 12px;
      height: 14px;
      transform: rotate(90deg);
      cursor: pointer;
    }
  }
}

.expanded__container {
  width: 100%;

  .expanded_top {
    width: 100%;
    height: 56px;
    max-height: 56px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .expanded_top__header {
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      font-weight: 600;
      letter-spacing: -0.34px;
      color: #212121;
      margin-left: 16px;
      flex: 1;
    }

    button {
      height: 17px;
      margin-right: 27px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.28px;
      color: #f42862;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .teeupItem_hideUsersArrow {
      margin: 24px 21px 24px auto;
      width: 12px;
      height: 14px;
      transform: rotate(-90deg);
    }
  }
}
