//TODO: we have too much colors vars during the redesign, so eventually we need to minimize the list of vars

:root {
  --black: #000;
  --transparentBlack: rgba(0, 0, 0, 0.4);
  --black2: #262626;
  --black3: #171717;
  --black4: #3a3a3a;
  --white: #fff;
  --white2: #fffbeb;
  --transparentWhite: #ffffff47;
  --lightGray: #f5f5f5;
  --lightGray2: #f0f2f5;
  --lightGray3: #aab0b8;
  --lightGray4: #f2f2f2;
  --lightGray5: #e5e5e5;
  --lightGray6: #fafafa;
  --lightGray7: #fbfaf8;
  --gray: #a3a3a3;
  --gray2: #808080;
  --gray3: #d4d4d4;
  --gray4: #737373;
  --gray5: #525252;
  --gray6: #404040;
  --gray7: #e3e5e8;
  --gray8: #8f8f8f;
  --darkGray: #595f82;
  --darkGray2: #696969;
  --radicalRed: #f42862;
  --radicalRed2: #f53263;
  --mainRed: #ff0000;
  --red2: #ef4444;
  --red3: #d9354d;
  --red4: #fac5c5;
  --red5: #fdecec;
  --red6: #d93e3e;
  --darkRed: #991b1b;
  --pink: #fee2e2;
  --pink2: #f5c7ce;
  --transparentRadicalRed: rgba(246, 107, 147, 0.5);
  --errorBorder: rgba(211, 74, 74, 0.32);
  --blue: #1393e1;
  --blue2: #124cae;
  --blue3: #256ee8;
  --darkBlue: #246fe2;
  --darkBlue2: #4c8aea80;
  --lightBlue: #c8daf9;
  --lightBlue: #58bdf1;
  --lightBlue2: #f2fafe;
  --lightBlue3: #eff7ff;
  --lightBlue4: #bed8ff;
  --lightBlue5: rgba(190, 216, 255, 0.32);
  --lightBlue6: #bbd2f6;
  --lightBlue7: #eff7ff;
  --transparentBlue: rgba(97, 192, 255, 0.5);
  --green: #00c5ae;
  --green2: #16a34a;
  --green3: #15803d;
  --darkGreen: #009c8a;
  --transparentGreen: rgba(67, 156, 81, 0.5);
  --mint: #00c5ae42;
  --lightMint: #f0fdf4;
  --lightMint2: #c4fff8;
  --lightMint3: #ebfffd;
  --lightMint4: #dcfce7;
  --lightYellow: #fde68a;
  --orange: #b45309;
  --orange2: #d97706;
  --grayShadow: 0 10px 15px -3px rgba(16, 24, 40, 0.1),
    0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  --grayShadow2: 0px -2px 7px -1px #a8aaad24;
  --blackShadow: 0 0 6px 0 rgba(82, 63, 63, 0.25);
  --blackShadow2: 0 2px 5px rgba(0, 0, 0, 0.2);
  --blackShadow3: 0px 0px 0px 4px #e8e8f0;
  --transparentBrown: rgba(211, 74, 74, 0.32);
  --primary-200: #eea2ad;
  --primary-50: #fdf6f7;
  --shadowBox1: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  --focusShadow: 0px 0px 0px 4px #e3e3e3;
  --iconNeutral: #a9b0b9;
  --violet: #9747ff;
  --lightViolet: #f4ebff;
  --dashed: repeating-linear-gradient(
    45deg,
    var(--lightGray5),
    var(--lightGray5) 2px,
    transparent 3px,
    transparent 9px
  );
}
