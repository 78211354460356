.suggest-in-person {
  // &__search-field {
    // display: flex;
    // flex-direction: column;
    // padding: 10px 12px;
    // box-sizing: border-box;
  // }

  &__search {
    width: 285px;
    margin-left: 15px;
    font-family: "greycliffcf-medium-salt";
    font-size: 15px;
    letter-spacing: -0.3px;
    text-align: left;
    color: #222;
    border: 0;

    &::placeholder {
      color: #a9b0b9;
    }

    &--wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      padding: 9px 0 9px 11.8px;
      border-radius: 4px;
      border: solid 1px #e3e5e8;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
}

.suggest-in-person-places {
  &__list {
    position: absolute;
    left: 12px;
    top: 155px;
    display: flex;
    flex-direction: column;
    width: 343px;
    height: 432px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 100%;
    height: 52px;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;

    &:hover {
      background-color: #f0f2f5;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-bottom: 7px;
    border-radius: 50%;
    background-color: #f0f2f5;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-family: "greycliffcf-regular-salt";
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: -0.24px;
    color: #a9b0b9;
  }

  &__name {
    margin-bottom: 3px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: 0.93;
    letter-spacing: -0.3px;
    color: #222;
  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 84px;
    height: 84px;
    margin-right: 4px;
    border-radius: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &--empty {
      width: 23px;
      height: 23px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 89px;
    height: 28px;
    margin-top: 12px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    letter-spacing: -0.26px;
    color: #595f82;

    border-radius: 15px;
    border: solid 1px #595f82;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #595f82;
      color: #fff;
    }
  }
}

.nearby {
  min-height: 108px;
}
