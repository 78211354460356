.duration-modal-container {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .duration-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    h4 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: #737373;
    }
  }

  .modal-body-labels {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightGray6);
    width: 400px;

    h5 {
      font-size: 16px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: var(--darkBlue);
    }
  }

  .wheel-picker-container {
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;

    .box-shadow-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 15px;
      z-index: 1;
      box-shadow: inset 0px 20px 25px rgba(255, 255, 255, 0.5),
        inset 0px -5px 15px rgba(255, 255, 255, 0.5);
    }

    .box-shadow-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 15px;
      z-index: 1;
      box-shadow: inset 0px -5px 15px rgba(255, 255, 255, 0.5),
        inset 0px 20px 25px rgba(255, 255, 255, 0.5);
    }

    & > ul {
      overflow-y: auto;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &:focus {
        outline: none;
      }

      & > li {
        user-select: none;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
        // background-color: #fff;
        transition: all 0.3s ease-in-out;

        &[aria-selected="true"] {
          border-radius: 4px;
          background-color: rgba(36, 111, 226, 0.1);
          color: var(--darkBlue);

          & > span:first-child {
            display: none;
          }
        }
      }
    }
  }
}
