.extraClassTeeup {
  font-size: 20px !important;
  pointer-events: auto !important;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.extraClass {
  font-size: 20px !important;
  pointer-events: auto !important;
  border-radius: 8px;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

#sort-tooltip {
  background-color: #ffffff;
  min-width: 120px;
  padding: 10px 0;
  // padding: 10px 15px;
  display: flex;
  border-radius: 8px;
  opacity: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  z-index: 9999 !important;
}

.teeup_tooltip_container_sortTeeup {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  overflow: hidden;
}

.tooltip_list_sortTeeup {
  // width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  &_img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  &:active {
    opacity: 0.5;
  }

  &__title {
    &_teeup {
      font-family: "greycliff-cf-medium";
      font-size: 16px;
      color: #222222;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.32px;
    }
  }
}

.tooltip_list_sortTeeup:hover {
  background-color: #f5f8fa;
  // background-color: #f5f5f5;
}

.sortTooltip {
  background-color: white !important;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  color: #222222 !important;
  border-radius: 8px;
  padding: 5px 0;
  opacity: 1 !important;

  &.place-bottom {
    margin-top: 6px !important;
    &:after {
      border-bottom-color: white !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
    }
  }

  .tooltip-list-option {
    display: flex;
    align-items: center;
    min-width: 180px;
    cursor: pointer;
    padding: 5px 10px;

    &:hover {
      background-color: #e3e5e8;
    }

    .tooltip-list-option-checkmark {
      width: 15px;
      margin-right: 6px;

      .checkmark {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tooltip-list-option-title {
      flex-grow: 1;
      font-size: 16px;
      font-family: "greycliff-cf-medium";
    }
  }
}
