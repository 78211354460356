@import "assets/styles/mediaSizes.scss";

.modal-participant-details {
  width: 430px;
  transition: 0.25s width;

  @media (max-width: $MOBILE) {
    width: 100%;
  }
}

.modal-participant-tabs {
  max-width: 700px;
}
