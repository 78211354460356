.list-header {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;

  .list-header-filter-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin-right: 8px;
    }

    & > button:last-of-type {
      margin-right: 0 !important;
    }

    .list-header-title {
      text-transform: capitalize;
      margin-right: 12px;
      font-size: 20px;
      font-weight: bold;
      font-family: "greycliff-cf-bold";
      line-height: 21px;
      letter-spacing: -0.4px;
      color: #222222;
    }
  }
}
