.other-suggestions {
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 500px;
  margin: -24px;
  overflow: auto;
}
