.button {
  height: 32px;
  border-radius: 20px;
  border: none;
  color: #a9b0b9;
  background-color: white;
  font-size: 13px;
  line-height: 21px;
  padding: 0 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
  font-family: "greycliff-cf-medium";
  cursor: pointer;

  .number-notification-indicator {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 0;
    font-family: "greycliff-cf-bold";
    font-weight: bold;
    margin-left: 4px;

    &__dot {
      background-color: white;
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }
  }

  .icon {
    height: 12px;
    width: auto;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
    }
  }

  &.border {
    border: solid 1px #a9b0b9;
  }

  // notification styles
  .number-notification-indicator {
    &.notification-default {
      background-color: #f0f2f5;
      color: #222222;
    }

    &.notification-primary {
      background-color: #f42862;
      color: white;
    }
  }

  &:hover,
  &.active {
    .number-notification-indicator {
      &.notification-default {
        background-color: white;
        color: #222222;
        .number-notification-indicator__dot {
          background-color: #e3e5e8;
        }
      }

      &.notification-primary {
      }
    }
  }

  // button types
  &.type-default {
    color: #a9b0b9;
    background-color: white;

    svg g,
    svg path,
    svg {
      fill: #a9b0b9 !important;
    }

    &.border {
      border: solid 1px #a9b0b9;
    }

    &:hover,
    &.active {
      background-color: #e3e5e8;
      color: #222222;

      svg g,
      svg path,
      svg {
        fill: #222222 !important;
      }

      &.border {
        border-color: #222222;
      }
    }
  }

  &.type-primary {
    color: white;
    background-color: #f42862;

    svg g,
    svg path,
    svg {
      fill: white !important;
    }

    &.border {
      border: solid 1px white;
    }
  }

  &.type-disabled {
    color: #a9b0b9;
    background-color: #f0f2f5;
    cursor: not-allowed;

    svg g,
    svg path,
    svg {
      fill: #a9b0b9 !important;
    }

    &.border {
      border: solid 1px #f0f2f5;
    }

    // &:hover,
    // &.active {
    //   background-color: #e3e5e8;
    //   color: #222222;

    //   svg g,
    //   svg path,
    //   svg {
    //     fill: #222222 !important;
    //   }

    //   &.border {
    //     border-color: #222222;
    //   }
    // }
  }
}
