.days-separator-container {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;

  .horizontal-line {
    width: 9px;
    height: 1px;
    background-color: black;
  }
  .vertical-line {
    border: 1px solid #fff;
    width: 1px;
    height: 102px;
    background-color: black;
    border-bottom: 0;
    border-top: 0;
  }
}
