.contacts-list__sort {
  width: 80px;
  border-bottom: 0 !important;

  div::before,
  div::after {
    border-bottom: 0 !important;
  }
  div {
    font-size: 14px;
    font-family: "greycliffcf-bold-salt";
    color: var(--gray4);
    font-weight: 500;
  }

  & > div > div {
    padding-right: 0 !important;
  }
}
