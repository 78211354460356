.reply-item {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightGray5);

  .reply-item-content-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 10px;

    .reply-item-info {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      h5 {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        margin-top: 4px;
        font-size: 13px;
        font-weight: 500;
        color: var(--gray4);
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #3f3f3f;
    }
  }
}
