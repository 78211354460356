.public-info-form {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    &-img--sso {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.edit-avatar-message {
  cursor: pointer;
  &:hover:after {
    width: 210px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
    color:  var(--darkGray);
    content: 'You can edit your profile picture after sign up is completed';
    font-size: 18px;
    font-family: greycliffcf-medium-salt;
    padding: 8px 16px;
    position: absolute;
    top: 50%;
    left: 120%;
    transform: translateY(-50%);
    text-align: center;
  }
}

.avatar--small {
  width: 70px;
  height: 70px;
  margin: 0;
}

.resend {
  color: var(--darkGray);
  cursor: pointer;
  text-align: end;
  margin-top: 14px;
}
