@import "assets/styles/mediaSizes.scss";

.collapse-container {
  border: 1px solid var(--lightGray5);
  transition: height 0.5s ease;
  border-radius: 8px;
}

.collapse-container-parent {
  @media (max-width: $MOBILE) {
    z-index: 200;
    background-color: #fff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .collapse-button:first-child {
      transform: rotate(-270deg) !important;
    }
  }
}

.collapse-header {
  padding: 16px;
  background-color: #fff;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: $MOBILE) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.collapse-header-top {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.collapse-title {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;

  & > h4 {
    font-size: 16px;
    font-weight: 700;
  }
}

.collapse-content {
  background-color: var(--lightGray6);
  transform-origin: top;
  transition: transform 0.3s ease, opacity 0.3s;
  transform: scaleY(0);
  overflow: hidden;
  opacity: 0;
  height: 0;
}

.collapse-content-open {
  transform: scaleY(1);
  opacity: 1;
  border-top: 1px solid var(--lightGray5);
  height: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.collapse-button {
  border: none;
  cursor: pointer;
  background-color: #fff;

  & > div {
    transition: 0.2s transform;
  }
}

.collapse-icon-rotate {
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
}

.collapse-header-border {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (max-width: $MOBILE) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
