.auth0-errors {
  &--wraper {
    width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__messages p {
    font-family: greycliffcf-medium-salt;
    font-size: 18px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    &--double {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }

  &__form {
    margin-top: 28px;
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }
  }
}
