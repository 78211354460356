.calendar-day-item {
  box-sizing: border-box;
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &__default {
    background-color: #fafafa;
    border-radius: 9999px;
    border: 1px solid #e5e5e5;
  }

  &__selected {
    background-color: #246fe2;
    border: 1px solid #246fe2;
    color: #fff;
  }

  &__outside-range {
    opacity: 0.5;
  }

  &__range__selected {
    background-color: #f5f5f5;
  }

  &__range__primary-selected {
    background-color: #2c2829;
    color: #fff;
    border-radius: 9999px;
  }
}
