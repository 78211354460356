.wheel-picker {
  font-family: "Roboto", sans-serif;

  & > ul::-webkit-scrollbar {
    display: none;
  }

  & > ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0;
  }

  span {
    display: none;
  }

  li[aria-selected="true"] {
    border-top: 1px solid #f0f2f5;
    // border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #f0f2f5;
    // border-bottom: 1px solid #F0F0F0;
  }
}
