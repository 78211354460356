.reaction-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightGray5);

  &:last-child {
    border-bottom: none;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }

  .reaction-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .reaction-item-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-left: 10px;

      h5 {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        color: var(--gray4);
      }
    }
  }
}
