.commented-suggestion {
  border-radius: 8px;
  background-color: #f0f2f5;
  padding: 8px 14px 8px 10px;
  color: #a9b0b9;
  font-weight: 600;
  font-family: "greycliffcf-regular-salt";
  font-size: 12px;
  margin-bottom: 10px;
  .commented-suggestion__commentator {
    display: flex;
    .commented-suggestion__commentator-icon {
      margin-right: 5px;
      width: 8px;
    }
  }

  .commented-suggestion__suggestion {
    margin-left: 14px;
  }
}
