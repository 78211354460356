.contact-itemNd {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 58px;
  margin-bottom: 4px;
  padding: 9px 11px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;

  &__avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f0f2f5;
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      font-weight: 500;
      color: #787f88;
      text-transform: uppercase;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    position: relative;
    &-sync {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: calc(100% + 7px);
      transform: translateY(-50%);
    }
  }

  &__name {
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.34px;
    text-align: left;
    color: #212121;

    &--wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__checkbox {
    position: absolute;
    right: 11.6px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__section {
    margin: 6px 0;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.31px;
    text-align: left;
    color: #222;
  }

  &__chose  {
    width: 11.4px;
    height: 11.4px;
    object-fit: contain;
  }

  &__buttons {
    position: absolute;
    top: 50%;
    right: 12px;
    display: flex;
    transform: translateY(-50%);
    z-index: 45;
  }

  &__button {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px 12px;
    border-radius: 16px;
    font-family: "greycliffcf-regular-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    box-sizing: border-box;

    &:hover {
      background-color: #f0f2f5;
    }
  }

  &__selected {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}


.from-invite {
  &:hover {
    border: solid 1px rgba(89, 95, 130, 0.5);
  }
}

.invited {
  border: solid 2px #595f82;
  &:hover {
    border: solid 2px #595f82;
  }
}

.tagNd {
  font-family: "greycliffcf-regular-salt";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #787f88;

  &__wrapper {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &__image {
    width: 10px;
    height: 10px;
    object-fit: contain;
    margin-right: 3px;
    margin-top: 3px;
  }

  &__content {
    display: flex;
    margin-top: 2px;
  }
}
