.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 40%;
    background: #000;
    z-index: 1;
  }

  .modal {
    // height: 664px;
    background: white;
    border-radius: 16px;
    z-index: 2;
    overflow: auto;
    height: 90vh;
  }
}
