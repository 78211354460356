.password-rules {
  margin-top: 15px;
  &__item {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: greycliffcf-regular-salt;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-icon {
      margin-right: 10px;
    }
  }
}
