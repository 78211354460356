.tabsview {
  display: flex;

  &__title {
    width: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 800;
    text-transform: uppercase;
    // font-family: 'greycliffcf-demibold-salt';
    font-family: "greycliffcf-extrabold-salt";
    color: #22222280;
    height: 40px;
    cursor: pointer;

    &--active {
      color: #222222;
      position: relative;

      &::after {
        content: "";
        display: block;
        height: 3px;
        background-color: #f42862;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
