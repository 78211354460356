.suggestion-item {
  position: relative;
  width: 331px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--lightGray5);
  background: #fbfaf8;
  box-sizing: border-box;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);

    &__status {
      border: 1px solid var(--lightGray5);
      padding: 2px 4px;
      font-size: 14px;
      font-weight: 600;
      &__success {
        background-color: #f0fdf4;
        color: #15803d;
        border: 1px solid #baedcd;
        font-size: 12px;
        padding: 2px 6px;
      }

      &__error {
        font-size: 12px;
        padding: 2px 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        border: 1px solid var(--red4);
        color: var(--red6);
        background-color: var(--red5);
        border-radius: 4px;
      }
    }

    span {
      font-weight: 600;
    }
  }
  .suggestion-item__content {
    display: flex;
    gap: 8px;
    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 16px;
      font-weight: 500;
      color: var(--black);

      &__time {
        color: var(--gray4);
        text-overflow: ellipsis;
        overflow: hidden;
        width: 195px;
        white-space: nowrap;

        &__url {
          color: var(--blue2);
        }
      }

      h4 {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
      }
    }

    &__reactions {
      margin-left: auto;
    }
  }
}
