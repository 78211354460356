.auth0-loading-p {
  &--wrapper {
    width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__message {
    font-family: greycliffcf-medium-salt;
    font-size: 18px;
  }
}
