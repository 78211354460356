.suggest-modal__header {
  display: flex;
  align-items: center;
  position: relative;

  .suggest-modal__header-title {
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
    margin: 12px 38px 12px 12px;
  }

  .suggest-modal__header-tabs {
    display: flex;
    font-family: "greycliffcf-bold-salt";
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.24px;
    text-align: center;
    color: #a9b0b9;
    text-transform: uppercase;
    cursor: pointer;

    .suggest-modal__header-tab {
      padding: 0 12px;

      span {
        padding: 15px 0 15px 0;
      }

      &.active {
        color: #222;
        span {
          border-bottom: 2px solid #f42862;
        }
      }
    }
  }

  .suggest-modal__header-close {
    justify-self: flex-end;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
      path {
        fill: #a9b0b9 !important;
      }
    }

    &:hover {
      & > div > div {
        path {
          fill: #222222 !important;
        }
      }
    }
  }
}
