.dates-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 40px;

  .title {
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      width: 13px;
      cursor: pointer;
    }
    img:first-child {
      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
      transform: rotate(90deg);
    }
    img:last-child {
      transform: rotate(-90deg);
    }

    span {
      text-align: center;
      width: 165px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .range {
    display: flex;
    gap: 8px;

    .range-item {
      cursor: pointer;
      width: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.5;
      }

      &__selected {
        & > div:first-child {
          color: #fff;
          border-radius: 9999px;
          background-color: black;
        }
      }

      &__disabled {
        opacity: 0.3;

        &:hover {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }

      & > div:first-child {
        width: 40px;
        height: 40px;
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > div:last-child {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        height: 20px;
        color: var(--gray8);
      }
    }
  }
}
