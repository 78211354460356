.timewheel-wrapper {
  height: 100%;
  position: relative;
  overflow-y: auto;
  &__hours-wrapper {
    height: 100%;
    background-color: #f0f2f5;
    overflow: hidden auto;
    position: relative;
  }
  .infinite-list {
    font-family: "greycliffcf-demibold-salt";
    z-index: 6;
    .hours-line {
      padding-top: 60px;
    }
    .hour-el {
      height: 50px;
      box-sizing: border-box;
      padding: 7px;
      border-right: 1px solid #fff;
      position: relative;
      &--disabled {
        background-color: rgb(204, 204, 204);
        color: rgba(34, 34, 34, .3);
      }
      &--day-line {
        background-color: #fff;
        border-color: lightgray;
        margin-top: 20px;
        position: relative;
      }
      &--daytime {
        background-color: #FFF9F0FF;
      }
      &--night {
        background-color: #3C446FFF;
        color: #fff;
      }
      &--in-between {
        background-color: #FFEBCBFF;
      }
      &__hour,
      &__day-period,
      &__date,
      &__month {
        display: block;
      }
      &__hour {
        font-size: 16px;
      }
      &__day-period {
        font-size: 10px;
      }
      &__date {
        font-size: 17px;
      }
      &__month {
        font-size: 10px;
      }
    }
  }
}

.hours-list-wrapper {
  background-color: #f0f2f5;
  padding-top: 20px;
  position: relative;
  &:last-child {
    padding-bottom: 20px;
  }
}

.unknown-tz {
  background-color: rgb(224, 224, 224);
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 5px 0;
  &--wrapper {
    margin-top: 25px;
  }
  &__img {
    width: 32px;
    height: 32px;
    background-color: rgb(177, 213, 219);
    border-radius: 50%;
    color: #fff;
    font-family: greycliffcf-demibold-salt;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__info {
    margin-left: 10px;
    &-email {
      color: rgb(127, 127, 127);
      display: block;
      font-family: greycliffcf-demibold-salt;
      font-size: 12px;
    }
    &-title {
      display: block;
      font-family: greycliffcf-bold-salt;
      font-size: 15px;
    }
  }
}
