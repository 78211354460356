.suggest-in-person-map {
  position: relative;
  width: 531px;
  min-height: 616px;

  &__button {
    position: absolute;
    bottom: 16px;
    right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 184px;
    height: 32px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 12px;
    letter-spacing: -0.24px;
    text-align: left;
    color: #595f82;

    border-radius: 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: pointer;
    z-index: 6;
    transition: 0.2s all;

    &:hover {
      background-color: #595f82;
      color: #fff;
      box-shadow: none;
    }
  }

  &__marker {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}