.teeup-settings {
  position: relative;
  width: max-content;
  height: 548px;
  padding: 0 12px;

  font-family: "greycliffcf-demibold-salt";
  font-size: 15px;
  letter-spacing: -0.2px;
  color: #222;

  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    margin-top: 8px;
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 345px;
    height: 100%;
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 196px;
    border-radius: 8px;
    background-color: #000;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &--buttons {
      display: flex;
      margin-top: 4px;
    }

    &--button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }

    
    &--input {
      position: absolute;
      top: 50%;
      width: 32px;
      height: 100%;
      border-radius: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0;
      z-index: 2;
    }
  }


  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    margin-top: 12px;
    padding-left: 12px;
    border-radius: 8px;
    border: solid 1px #e3e5e8;
    background-color: #f8fafc;
    box-sizing: border-box;

    &--buttons {
      display: flex;
    }
  }

  &__input {
    font-family: "greycliffcf-bold-salt";
    font-size: 21px;
    border: 0;
    background-color: transparent;
  }

  &__change-name {
    width: 40px;
    height: 43px;
    border-radius: 8px;
    margin: 1px -1px 0 0;
    border: 0;
    border-right: solid 1px #e3e5e8;
    border-bottom: solid 1px #e3e5e8;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #e3e5e8;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 228px;
    margin-top: 12px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 1px #e3e5e8;
    background-color: #f8fafc;
    box-sizing: border-box;
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 44px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    color: #212121;

    border-bottom: solid 1px #e3e5e8;
    box-sizing: border-box;

    cursor: pointer;

    &--description {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      line-height: 1.31;
      letter-spacing: -0.26px;
      color: #787f88;
      margin-top: 4px;
    }
  }

  &__toggle-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-45%);
  }

  &__subtitle {
    display: flex;
    align-items: center;
    height: 40px;
    margin: 3px 0 0;
    font-family: "greycliffcf-extrabold-salt";
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: solid 1px #e3e5e8;
    box-sizing: border-box;

    &:last-of-type {
      margin-top: 10px;
    }
  }
}

.right-side {
  height: 492px;
  padding: 0 12px 0;
  border: solid 1px #e3e5e8;
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: 15px;
}

.confirm-action {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 400px;
  height: 200px;

  padding: 7px 50px;

  border-radius: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    width: 300px;
    margin-top: 30px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: center;
    color: #222;
  }

  &__buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  &__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    height: 32px;
    margin-right: 10px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #f42862;
    text-transform: uppercase;

    border: solid 2px #f42862;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    height: 32px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #fff;
    text-transform: uppercase;

    border: solid 2px #f42862;
    border-radius: 16px;
    background-color: #f42862;
    box-sizing: border-box;
    cursor: pointer;
  }  
}
