.general-duration {
  margin-top: 16px;

  .duration-box {
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    border: 1px solid var(--gray);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    p {
      font-size: 14px;
      font-weight: 700;
    }

    // img {
    //   transform: rotate(180deg);
    // }
  }
}
