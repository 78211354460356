.teeups-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;
  // height: 90vh;
  // overflow: hidden;
}
