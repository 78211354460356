.suggest-pinboard-online {
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  &__placeholder {
    color: #a9b0b9;
  }

  &__icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
    object-position: center;
    margin-right: 4px;
  }

  &__app {
    display: flex;
    align-items: center;
  }
}