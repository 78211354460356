@import "../../assets/styles/mediaSizes";

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

.create-teeup {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  overflow-y: auto;

  @media (max-width: $MOBILE) {
    padding-top: 100px;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }

  &__header {
    @extend .create-teeup__content;
    border-radius: 16px 16px 0 0;
    margin-top: 8px;
    padding: 12px;
  }

  &__footer {
    @extend .create-teeup__content;
    border-radius: 0 0 16px 16px;
    padding-top: 10px;
    row-gap: 10px;
  }

  &__title {
    mix-blend-mode: multiply;
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
    &-wrapper {
      padding-top: 21px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--lightGray);
    box-sizing: border-box;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 12px;
    border-radius: 8px;
  }

  &__image {
    &-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }

    &-input {
      position: absolute;
      top: 50%;
      width: 32px;
      height: 100%;
      border-radius: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0;
      z-index: 2;
    }
  }

  &__top {
    position: relative;
    width: 100%;
    display: flex;
  }

  &__name {
    width: 100%;
    height: 36px;
    padding: 7px 7px 9px 8px;
    border-radius: 8px;
    border: solid 1px #f0f2f5;
    background-color: #fff;
    box-sizing: border-box;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.3px;
    text-align: left;
    color: #222;
    margin-bottom: 5px;

    &::placeholder {
      font-family: "greycliffcf-demibold-salt";
      height: 18px;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.3px;
      text-align: left;
      color: #a9b0b9;
      text-overflow: ellipsis;
    }

    &-wrapper {
      position: relative;
      margin-right: 12px;
      flex-grow: 1;
    }

    &--error {
      position: absolute;
      bottom: 0;
      left: 15px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      text-align: left;
      color: #f42862;
    }
  }

  &__star {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }

  &__settings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #f0f2f5;
    cursor: pointer;
  }

  &__subtitle {
    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #222;
  }

  &__people {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__flex-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
  }

  &__content--section {
    width: 100%;
  }

  &__link {
    position: absolute;
    top: 5px;
    right: 0;
    display: flex;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #a9b0b9;
    cursor: pointer;
  }

  &__invite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    border: solid 2px #595f82;
    background-color: #fff;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    transition: border 0.1s, color 0.1s;
    cursor: pointer;

    &:hover {
      border: solid 2px #f42862;
      color: #f42862;
    }

    &--error {
      position: absolute;
      left: 15px;
      bottom: 2px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      text-align: left;
      color: #f42862;
    }

    &--active {
      margin-bottom: 12px;
    }

    &-wrapper {
      &--active {
      }
    }

    &-arrow {
      position: absolute;
      right: 11px;
      top: 15px;
      transition: transform 0.2s;
      cursor: pointer;

      &--close {
        transform: rotate(180deg);
      }
    }
  }

  &__invitees {
    display: flex;
    width: 90%;
    margin-top: 4px;
    margin-bottom: 12px;

    &--open {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__me {
    position: relative;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &--open {
      margin-top: 8px;
    }

    &-info {
      margin-top: 6px;
      cursor: pointer;
    }

    &-name {
      height: 18px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.3px;
      text-align: left;
      color: #212121;
    }

    &-email {
      height: 16px;
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: -0.2px;
      text-align: left;
      color: #a9b0b9;
    }
  }

  &__crown {
    position: absolute;
    left: -8px;
    top: -8px;
  }

  &__gameplan {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 25px;
  }

  &__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 40px;
    margin-right: 12px;
    border-radius: 20px;
    border: solid 2px #f42862;
    background-color: #fff;
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: -0.3px;
    text-align: center;
    color: #f42862;
    cursor: pointer;
  }

  &__suggest {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 40px;
    border-radius: 20px;
    border: 0;
    background-color: #f42862;
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: -0.3px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  &__popup {
    &-wrapper {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(34, 34, 34, 0.3);
      z-index: 1;
    }
  }

  &__user {
    position: relative;
    display: flex;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;
    cursor: pointer;

    &--open {
      align-items: center;
      width: 100%;
      margin-left: -9px;
      padding: 6px 9px;

      &:hover {
        background-color: #f0f2f5;
      }
    }

    &-avatar {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &--cross {
        position: absolute;
        top: -5px;
        right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border: solid 1px #fff;
        border-radius: 50%;
        background-color: #f0f2f5;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.2s;
      }

      &--icon {
        opacity: 0.6;
      }
    }

    &-manual {
      background-color: rgb(225, 225, 225);
      color: rgb(76, 76, 76);
      font-size: 17px;
      font-family: "greycliffcf-medium-salt";
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    &-info {
      margin-top: -3px;
      margin-left: 10px;
      cursor: pointer;
    }

    &-name {
      height: 18px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: -0.3px;
      text-align: left;
      color: #212121;
    }

    &-email {
      height: 16px;
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: -0.2px;
      text-align: left;
      color: #a9b0b9;
    }
  }
}

.suggestNd {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 120px;
  margin-top: 12px;
  border-radius: 8px;
  border: solid 1px #f0f2f5;
  background-color: #fff;
  overflow: hidden;
  transition: height 0.3s;

  &__content {
    margin: 12px 12px 0 12px;
  }

  &__type {
    display: flex;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 10px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.45px;
    text-align: left;
    color: #a9b0b9;
    text-transform: uppercase;
  }

  &__decision {
    margin-top: 4px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.3px;
    text-align: left;
    color: #222;
  }

  &__suggestion {
    font-family: "greycliffcf-medium-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: oblique;
    line-height: 1.69;
    letter-spacing: -0.26px;
    text-align: left;
    color: #ff6f25;
  }

  &__button {
    position: absolute;
    bottom: -1px;
    left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: solid 1px #f0f2f5;
    background-color: #fff;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #f0f2f5;
    }
  }

  &__text {
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    transition: color 0.2s;
  }
}

.teeup-permissions {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 370px;
  height: max-content;
  border-radius: 16px;
  background-color: #fff;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  &__wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(34, 34, 34, 0.3);
    z-index: 14;
  }

  &__cross {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  &__title {
    height: 24px;
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
  }

  &__content {
    width: 100%;
    height: 100%;
    margin-top: 12px;
    padding: 16px 12px 0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  &__subtitle {
    height: 14px;
    margin-bottom: 13px;
    font-family: "greycliffcf-extrabold-salt";
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: left;
    color: #222;
    text-transform: uppercase;

    &:last-of-type {
      margin-top: 29px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 8px 0;
    border-top: #e3e5e8 1px solid;
    cursor: pointer;
  }

  &__text {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    text-align: left;
    color: #222;

    &--wrapper {
      display: flex;
      flex-direction: column;
    }

    &--active {
      margin-left: 25px;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__span {
    margin-top: 8px;
    font-family: "greycliffcf-regular-salt";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #787f88;
  }
}

.suggestNd__button:hover .suggestNd__text {
  color: #222;
}

.create-teeup__user:hover .create-teeup__user-avatar--cross {
  opacity: 1;
  z-index: 1;
}

.create-teeup__user-avatar--cross:hover .create-teeup__user-avatar--icon {
  opacity: 1;
}

.empty {
  color: #a9b0b9;
}
