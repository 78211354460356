.suggest-where-in-person {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 40vh;
  overflow-y: auto;

  & > ul {
    display: flex;
    gap: 8px;
  }
}
