.people-attendance-options {
  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  ul {
    margin-top: 8px;
    display: flex;
    list-style: none;
    gap: 8px;

    li {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid var(--lightGray5);
      color: var(--gray5);
      padding: 0 10px 0 10px;
      display: flex;
      align-items: center;
      gap: 4px;
      border-radius: 54px;
      height: 32px;
      box-sizing: border-box;

      &:hover {
        border-color: var(--lightGray5);
        color: var(--gray4);
        transition: all 0.2s ease-in-out;
      }

      span {
        height: 15px;
        line-height: 1;
      }

      div {
        width: 17px;
        height: 17px;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}
