.date-picker {
  width: 370px;
  height: max-content;
  background-color: #fff;
  padding: 10px 0;
  margin: 0 auto;

  .react-calendar__navigation__label,
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button,
  .react-calendar__navigation,
  .custom-non-click-day {
    pointer-events: none;
  }

  .react-calendar__navigation__label,
  .react-calendar__month-view__weekdays__weekday {
    color: rgb(34, 34, 34);
    font-family: greycliffcf-bold-salt;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day {
    height: 18px;
    display: block;
    background-color: transparent;
    border: none;
    text-align: center;
    margin-top: 15px;
    line-height: 17px;
    abbr {
      width: 18px;
      height: 18px;
      cursor: pointer;
      border-radius: 6px;
      display: block;
      font-size: 11px;
      font-family: greycliffcf-bold-salt;
      margin: 0 auto;
    }
  }

  .react-calendar__tile {
    &--now abbr {
      background-color: rgb(227, 227, 227);
    }
    &--active abbr {
      background-color: rgb(244, 40, 98);
      color: #fff;
    }
  }

  .custom-non-click-day,
  .custom-disabled-day {
    abbr {
      cursor: default;
      color: rgb(216, 216, 216);
    }
  }

  .custom-non-click-day {
    pointer-events: none;
  }

  .custom-neighbor-month abbr {
    cursor: pointer;
    color: rgb(216, 216, 216);
  }

  .hidden-picker-day,
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button,
  .react-calendar__navigation {
    display: none;
  }

  .today-start,
  .today-end {
    abbr {
      width: calc(18px * 2);
      background-color: rgb(244, 40, 98);
      color: #fff;
      box-sizing: border-box;
    }
  }

  .today-start abbr {
    margin: 0 0 0 auto;
    text-align: left;
    padding-left: 5px;
    border-radius: 6px 0 0 6px;
  }
  .today-end abbr {
    margin: 0 auto 0 0;
    text-align: right;
    padding-right: 5px;
    border-radius: 0 6px 6px 0;
  }
}
