.time-wheel-container {
  display: flex;
  justify-content: center;
  overflow: hidden;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 63px !important;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--lightBlue);
    color: var(--blue2);
    font-size: 13px;
    font-weight: 600;
    &:not(:first-child) {
      border-left: 0;
    }
  }
}
