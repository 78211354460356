@import "assets/styles/mediaSizes.scss";

.people-ask-people-content {
  width: 700px;
  height: 90vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $MOBILE) {
    width: 100%;
  }
}

.people-ask-people-participant {
  border-bottom: 0 !important;

  .people-participant-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4 {
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 2px;
    }
    span {
      font-weight: 500;
    }
  }

  .people-participants-icon {
    width: 40px;
    height: 40px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.people-ask-people-footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 16px;
  border-top: 1px solid var(--lightGray);
  opacity: 0;

  button {
    width: 330px;
    padding: 12px 0;
    font-size: 15px;
    font-weight: 600;
    border-radius: 84px;
    border: 0;
    cursor: pointer;

    &:last-child {
      background-color: var(--black);
      color: var(--white);
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.2s;
  opacity: 0;
}
.fade-in {
  animation: fadeIn 0.2s;
  opacity: 1;
}

.people-ask-people-select-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  padding-right: 16px;
  border-bottom: 1px solid var(--lightGray);
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 9999px;
      background-color: var(--lightGray);
    }
  }

  span {
    font-size: 15px;
    font-weight: 600;
  }
}

.people-ask-people-container {
  overflow: auto;
  height: 100%;
  margin-right: -16px;
}
