.people-action-button {
  cursor: pointer;
  border: 1px solid var(--gray3);
  padding: 12px 16px;
  color: var(--gray4);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 90px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--lightGray6);
  height: 40px;
  transition: opacity 0.3s;
  vertical-align: bottom;
  transition: 0.2s border-color, 0.2s background-color, 0.2s color;

  &:hover {
    opacity: 0.8;
  }
}

.people-action-button-primary {
  background-color: #fff !important;
  color: var(--gray5) !important;
  border: 1px solid var(--lightGray5) !important;
}

.people-action-button-selected {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

.people-action-button-disabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
