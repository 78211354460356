.time-picker-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 200px;
  cursor: grab;

  .time-picker-grid {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
