.calendar {
  &__title {
    line-height: 25px;
    font-size: 20px;
    font-family: "greycliffcf-demibold-salt";
    color: rgb(0, 0, 0);
    margin-left: 10px;
  }

  &__wrapper {
    display: flex;
    flex: 1;
    overflow: auto;

    &-profile {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      flex-direction: row;
      overflow: auto;
      // scroll-behavior: smooth;
      // scrollbar-width: none; /* Firefox */
      // -ms-overflow-style: none;  /* IE 10+ */

      // &::-webkit-scrollbar {
      //   width: 0px;
      //   background: transparent;
      // }
    }
  }
}

.slick-track {
  display: flex;
  height: 100%;
}

.slick-slide {
  height: 100%;

  & > div {
    height: 100%;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }
}

.fullHeight__calendar {
  .slick-slide {
    height: 100%;

    & > div {
      height: 100%;

      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  }
}

.slick-slider {
  height: 100%;
}

.slick-list {
  height: 100%;
}
