.people-column-dropdown {
  position: absolute;
  right: 0;
  bottom: -112px;

  display: flex;
  flex-direction: column;

  width: 196px;
  height: 168px;
  padding: 4px;

  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  box-sizing: border-box;
  z-index: 10;

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    width: 100%;
    height: 52px;
    border-radius: 2px;

    &:hover {
      background-color: #f0f2f5;
    }

    &:hover .people-column-dropdown__title {
      color: #222;
    }
  }

  &__icon {
    position: absolute;
    top: 8px;
    left: 4px;
  }

  &__title {
    margin-left: 28px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
  }
}
