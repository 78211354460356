.invite-to-teeup {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 608px;
  height: 652px;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: #f0f2f5;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 20;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: -0.34px;
    text-align: left;
    color: #222;
  }

  &__cross {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  &__img {
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__filter {
    display: flex;
    height: 32px;
    margin: 12px 0 16px;
  }

  &__search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 9px 0 8px 11px;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;
  }

  &__input {
    width: 400px;
    height: 15px;
    margin-left: 6px;
    border: 0;
    color: #222;

    &::placeholder {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: -0.26px;
      text-align: left;
      color: #a9b0b9;
    }
  }

  &__resent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 108px;
    height: 100%;
    margin-left: 8px;
    border-radius: 16px;
    background-color: #fff;
    font-family: "greycliffcf-regular-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    cursor: pointer;

    &--active {
      background-color: #595f82;
      color: #fff;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;

    &__empty-filtered {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      font-family: "greycliffcf-demibold-salt";
      font-size: 20px;
      color: rgba(137, 149, 164, 0.6);
      line-height: 24px;
      letter-spacing: -0.47px;
    }

    &__empty-placeholder {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        max-width: 325px;
        padding: 16px 0 28px 0;
        font-family: "greycliffcf-demibold-salt";
        font-size: 20px;
        color: #8995a4;
        text-align: center;
        line-height: 24px;
        letter-spacing: -0.47px;
      }

      &-btn-ok {
        padding: 10px 42px;
        background-color: #f42862;
        border: 0;
        border-radius: 34px;
        font-family: "greycliffcf-demibold-salt";
        font-size: 17px;
        color: #fff;
        line-height: 17px;
        letter-spacing: -0.34px;
        cursor: pointer;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      padding-right: 6px;
      border: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: #a9b0b9;
    }
  }
}
