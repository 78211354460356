@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.time-wheel-tab {
  animation: fadeIn 0.3s ease-in-out;
  background-color: #fff;
  padding-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -16px;
  display: flex;
  flex-direction: column;

  .dates-tab {
    display: flex;
    flex-direction: column;
  }
}
