.chatinput {
  width: calc(100% - 80px);
  min-height: 40px;
  max-height: 95px;
  position: relative;

  &__highlighter__substring {
    font-family: "greycliff-cf-medium" !important;
    color: #222222 !important;
    font-size: 17px !important;
    line-height: 21px !important;
  }

  &__control {
    width: 100%;
    // height: 40px;
  }

  &__suggestions {
    bottom: 46px !important;
    top: auto !important;
    left: -61px;
    &__list {
      overflow-y: scroll !important;
      max-height: 200px;
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 8px;
      width: 491px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__input {
    // top: 5px !important;
    left: 5px !important;
    font-family: "greycliff-cf-medium" !important;
    color: #222222 !important;
    font-size: 17px !important;
    line-height: 21px !important;
    border: 0;
    // padding: 15px 0 10px 10px;
    padding: 10px 0 8px 10px;
    width: calc(100% - 100px);
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  &__input::placeholder {
    position: relative;
    top: 5px;
    left: 0;
    // left: 10px;
    font-family: "greycliff-cf-medium";
    font-size: 17px;
    line-height: 12px;
    color: #9d9d9d;
  }

  &__input:focus {
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    color: #9d9d9d;
  }

  &__img {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__wrapper {
    flex: 1;
    margin: 5px 0;
  }

  &__form {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-right: 17px;
  }

  &__suggestions__item {
    display: flex;
    align-items: center;

    &--focused {
      background-color: #f0f2f5;
      // background-color: #F0F0F0;
    }
  }
}

.input_wrapper {
  width: 100%;
  max-width: 411px;
  // max-width: 378px;
  box-sizing: border-box;
  // border: 2px solid #E1E1E1;
  background-color: #f5f8fa;
  // background-color: #f5f5f5;
  border-radius: 24px;
}

.files_wrapper {
  display: flex;
  flex-direction: row;
}

.file_preview_container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
}

.remove_file_icon {
  align-self: flex-end;
  position: absolute;
  top: -10px;
  right: -1px;
  margin-top: 2px;
}

.send-button {
  position: absolute;
  right: 50px;
  bottom: 7px;
  // bottom: 10px;
  height: 25px;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: #f42862;
  }
}

.mention {
  &-button {
    position: absolute;
    right: 15px;
    // bottom: 8px;
    bottom: 5px;
    height: 30px;
  }

  &-avatar {
    margin: 8px 8px 8px 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &-name {
    font-family: "greycliff-cf-medium";
    font-size: 17px;
  }
}

.download-link {
  color: rgb(244, 40, 98);
  text-decoration: none;
  font-family: "greycliffcf-demibold-salt";
  font-size: 14px;
  padding-bottom: 5px;
}

.file-upload {
  display: flex;
  flex-direction: row;
  // margin-right: 4px;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 40px;
  width: 40px;

  > input {
    display: none;
  }

  &__plus_image {
    height: 32px;
    width: 32px;

    &:hover {
      border-radius: 50%;
      background-color: #f0f2f5;
      // background-color: #f0f0f0;
    }
  }
}

.close-icon {
  width: 20px;
  height: 20px;
}

.img-preview {
  width: 7rem;
  height: 7rem;
}

.file-preview {
  text-align: center;
  border: 1px solid rgb(224, 224, 224);
  padding: 10px;
  display: flex;

  &__name {
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    line-height: 22pt;
    letter-spacing: -0.4pt;
    color: rgb(34, 34, 34);
  }

  &__size {
    font-family: "greycliffcf-regular-salt";
    font-size: 15px;
    line-height: 20pt;
    letter-spacing: -0.2pt;
    color: rgb(157, 157, 157);
  }
}

.reply-container {
  background-color: rgb(240, 240, 240);
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.reply-message {
  font-family: "greycliff-cf-medium";
  font-size: 17px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.reply-title {
  font-family: "greycliff-cf-medium";
  font-size: 15px;
  color: rgb(157, 157, 157);
  margin-left: 5px;
}
.reply-close {
  width: 15px;
  height: 15px;
}

// ==== NEW DESIGN ====
.chat-input {
  width: 100%;
  background-color: white;
  border: 1px solid #e3e5e8;
  border-radius: 4px;
  margin: 12px;

  .chat-input__files {
    display: flex;
    align-items: center;
  }

  .chat-input__text-input {
    min-height: 32px;

    .chatinput {
      width: 100%;
      max-height: 190px;
      min-height: 32px;
    }

    .chatinput__input {
      padding: 0 5px 5px 5px;
      margin-top: 5px !important;
      left: 0 !important;
      box-sizing: border-box;
    }

    .chatinput__highlighter__substring,
    .chatinput__input,
    .chatinput__input::placeholder {
      font-size: 15px !important;
      word-break: break-all;
    }

    .chatinput__suggestions {
      width: calc(100% + 26px);
      left: -13px;
      border-radius: 8px;
      z-index: 5 !important;
    }
    .chatinput__suggestions__list {
      width: 100%;
    }
  }

  .chat-input__actions {
    height: 32px;
    margin-top: 4px;
    padding: 2px;

    #media-input {
      display: none;
    }

    .chat-input__action-button {
      height: 28px;
      width: 28px;
      border-radius: 4px;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      & > div > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: #f0f2f5;
        path {
          fill: #222222 !important;
        }
      }

      &:last-child {
        float: right;
      }
    }
  }

  &.inside-comments-tab {
    margin: 0;
  }
}

// ==== FILE CARD ====
.file-card__close {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  & div > div {
    display: flex;
  }
}

.file-card__image-type {
  margin-right: 8px;
  width: fit-content;
  position: relative;

  img {
    position: relative;
    height: 56px;
    width: 56px;
    border-radius: 4px;
    background-color: #f0f2f5;
    object-fit: cover;
  }
}

.file-card__document-type {
  position: relative;
  height: 56px;
  width: 146px;
  border: 1px solid #f0f2f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  .file-card__document-type__icon {
    margin: 0 8px 0 12px;

    & div > div {
      display: flex;
    }
  }

  .file-card__document-type__info {
    display: flex;
    flex-direction: column;
    .file-card__document-type__info-name {
      font-family: "greycliff-cf-medium";
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #222;

      display: flex;
      span {
        white-space: nowrap;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .file-card__document-type__info-size {
      font-family: "greycliff-cf-medium";
      font-size: 11px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.22px;

      color: #a9b0b9;
    }
  }
}

// ==== Carousel ====
.file-card-carousel {
  overflow: hidden;
  position: relative;
}

.file-card-carousel__card-wrapper {
  display: flex;
  min-width: fit-content;
  left: -40px;
  position: relative;
  padding-top: 8px;
  &:first-child {
    margin-left: 8px;
  }
}

.file-card-carousel__arrows {
  position: absolute;
  border-radius: 50%;
  z-index: 200;
  background-color: yellow;
  width: 28px;
  height: 28px;
  margin: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
