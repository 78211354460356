.people-participants-content {
  background-color: #fff;
  border-top: 0;
}

.people-participant-block {
  padding: 0 32px;
}

.people-participant-view-less {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  color: var(--gray4);
  font-size: 13px;
  font-weight: 600;
  user-select: none;
}
