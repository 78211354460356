.selectPeopleToNudge {
  width: 400px;
  // max-height: 424px;
  // height: 424px;
  border-radius: 8px;
  background-color: #ffffff;
  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    overflow-x: hidden;
  }
  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;

    &_shadow {
      width: 100%;
      height: 4px;
      opacity: 0.08;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    }
    &__button {
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.34px;
      color: rgb(244, 40, 98);
      outline: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      &_selectAll {
        margin-left: auto;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        background: transparent;
        cursor: pointer;
        &_text {
          font-family: "greycliffcf-demibold-salt";
          font-size: 17px;
          color: #000000;
        }
        &_avatar > img {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
      }
    }
    &__title {
      // font-family: 'greycliffcf-demibold-salt';
      font-family: "greycliffcf-bold-salt";
      font-size: 20px;
      line-height: 1.05;
      letter-spacing: -0.4px;
      text-align: center;
      color: #222222;
      &__selectedPeople {
        font-family: "greycliffcf-demibold-salt";
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.3px;
        color: rgb(157, 157, 157);
        text-align: center;
      }
    }
  }
  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    &_shadow {
      width: 100%;
      height: 4px;
      opacity: 0.08;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
    }
    &__button {
      width: 148px;
      height: 36px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      line-height: 1;
      letter-spacing: -0.34px;
      margin: 25px 0;
      border-radius: 34px;
      cursor: pointer;
      &_cancel {
        background-color: #ffffff;
        color: #f42862;
        margin-right: 8px;
        border: solid 2px #f42862;
      }
      &_nudge {
        background-color: #f42862;
        color: #ffffff;
        border: none;
      }
    }
  }
}

.select-all__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 12px 26px 12px 12px;
}

.mycontact__wrapper {
  max-height: 276px;
  overflow-y: scroll;
  margin-right: 5px;
  margin-top: 4px;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d6d6d6;
  }
}
