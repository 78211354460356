.people-participants-status {
  position: absolute;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border-radius: 9999px;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 20;
  }
}

.people-participants-status-large {
  width: 17px;
  height: 17px;
}

.people-participants-status-block {
  position: relative;
}

.people-participants-status-disabled {
  // opacity: 0.5;
}

.people-participants-status-xl {
  width: 34px;
  height: 34px;
}
