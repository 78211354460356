.custom-autocomplete {
  width: 100% !important;
  li {
    width: 100% !important;
  }
  & > div > div {
    padding: 0 !important;
  }
}

.suggest-where-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;

  li {
    cursor: pointer;
  }
}

.suggest-where-pill-item {
  font-weight: 500;

  b {
    font-weight: 600;
  }
}

.suggest-where-search-list {
  padding: 0 !important;
}
