.list-filter {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin: 10px 0;

  .list-filter-options {
    display: flex;
    flex-direction: row;

    .filter-section {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      width: fit-content;

      .filter-section-title {
        font-family: "greycliff-cf-bold";
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        color: #222;
        margin-bottom: 10px;
      }

      .filter-options {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-right: 1px solid #e3e5e8;
        max-height: 180px;
        height: 100%;
        padding-right: 15px;

        .filter-option-label {
          font-size: 13px;
          font-family: "greycliff-cf-medium";
          color: #8995a4;
        }
      }

      &:last-child {
        margin-right: 0;
        .filter-options {
          border-right: none;
        }
      }
    }
    .single-row-section > .filter-options{
      flex-wrap: nowrap;
      max-height: none;
    }

    .double-row-section {
      width: 35%;
    }
  }

  .list-filter-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #e3e5e8;
    padding: 20px 0 0 0;
    margin-top: 20px;
    & > * {
      margin: 0 7px;
    }
  }
}
