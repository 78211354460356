.ComingUpTab {
  display: flex;
  width: 100%;
  flex-direction: column;
  // justify-content: center;

  &_header {
    text-transform: capitalize;
    font-family: "greycliff-cf-bold";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222222;
    // padding-left: 16px;
  }
}
