.inviteNd {
  position: relative;
  width: 936px;
  height: 655px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
  }

  &__content {
    display: flex;
    height: 600px;
  }

  &__contacts {
    width: 640px;
    height: 100%;
    margin: 12px 12px 0 0;
    padding: 0 12px 0 12px;
    border-radius: 8px;
    background-color: #f0f2f5;
    box-sizing: border-box;
  }

  &__header {
    width: 100%;
    min-height: 39px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e3e5e8;
  }

  &__item {
    font-family: "greycliffcf-bold-salt";
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.24px;
    text-align: center;
    color: #a9b0b9;
    cursor: pointer;

    &--active {
      position: relative;
      color: #000;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 3px;
        background-color: #f42862;
      }
    }
  }

  &__rightside {
    display: flex;
    flex-direction: column;
  }

  &__error {
  }
}

.inviteNd-by {
  position: relative;
  width: 260px;
  min-height: 180px;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f0f2f5;
  box-sizing: border-box;

  &--error {
    min-height: 190px;
  }

  &__title {
    position: relative;
    width: 100%;
    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: -0.34px;
    text-align: left;
    color: #222;

    &::after {
      content: "";
      position: absolute;
      bottom: -23px;
      left: 0;
      width: 100%;
      height: 1px;
      margin: 8px 0 13px;
      border-radius: 0.5px;
      background-color: #e3e5e8;
    }
  }

  &__input {
    width: 100%;
    height: 32px;
    margin-top: 22px;
    padding: 8px 0 9px 12px;
    border-radius: 16px;
    border: 0;
    background-color: #fff;
    box-sizing: border-box;

    &::placeholder {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.26px;
      text-align: left;
      color: #a9b0b9;
    }

    &--error {
      border: solid 1px #f52d22;
    }
  }

  &__choice {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 12px;
    font-family: "greycliffcf-regular-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #222;
    cursor: pointer;

    &--container {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    border-radius: 6px;
    border: solid 1px #e3e5e8;
    background-color: #fff;

    &--active {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #222;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    margin: 20px 0 0;
    border-radius: 16px;
    border: solid 1px #f42862;
    background-color: #f0f2f5;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: center;
    color: #f42862;
    cursor: pointer;

    &--error {
      margin-top: 30px;
    }
  }

  &__error {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 200px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #f52d22;
  }
}

.inviteNd-invitees {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  overflow: auto;
  height: 349px;
  width: 106%;
  margin-left: -7px;

  &--error {
    height: 339px;
  }

  &::-webkit-scrollbar {
    /* chrome based */
    width: 0px; /* ширина scrollbar'a */
    background: transparent; /* опционально */
  }

  &__wrapper {
    width: 260px;
    // height: 100%;
    margin-top: 12px;
    padding: 12px;
    border-radius: 8px;
    background-color: #f0f2f5;
    box-sizing: border-box;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: -0.34px;
    text-align: left;
    color: #222;
  }

  &__input {
    width: 100%;
    height: 32px;
    margin-top: 22px;
    padding: 9px 12px 9px 12px;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;
    border: 0;

    &::placeholder {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #a9b0b9;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    margin-bottom: 4px;
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: default;
    justify-content: space-between;

    &:hover {
      background-color: #e3e5e8;
    }

    &:hover .inviteNd-invitees__cross {
      display: block;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__content {
      display: flex;
      align-items: center;
    }
  }

  &__avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &--manual {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: rgb(225, 225, 225);
      color: rgb(76, 76, 76);
      font-size: 17px;
      font-family: "greycliffcf-medium-salt";
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
  }

  &__name {
    margin-left: 8px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: left;
    color: #212121;
  }

  &__cross {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover svg {
      color: #222 !important;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 16px;
    border: solid 1px #f42862;
    background-color: #f0f2f5;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: center;
    color: #f42862;
    cursor: pointer;

    &--error {
      margin-top: 30px;
    }

    &--wrapper {
      position: sticky;
      bottom: 0;
      width: 100%;
      height: 56px;
      padding: 12px;
      box-sizing: border-box;
      background-color: #f0f2f5;
    }
  }
}
