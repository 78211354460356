.suggestion-where-search-option {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--lightGray);
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    & > span {
      display: flex;
      align-items: center;
    }
  }

  & > img {
    width: 20px;
    height: 20px;
    margin: auto 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dedede;
  }
}

.suggestion-where-search-separator {
  cursor: default;
  &:hover {
    background-color: transparent;
  }
}
