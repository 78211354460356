.verify-modal__wrapper {

  .titel-info {
    color: #767676;
    margin-bottom: 16px;
  }

  .verification-method-value__container {
    padding: 10px 12px;
    word-break: break-word;
    margin-bottom: 16px;
  }

  .verification-method-value__wrapper {
    padding: 10px 12px;
    word-break: break-word;
    margin-bottom: 16px;
  }

  .action_wrapper {
    display: flex;
    gap: 4px;
    flex-direction: column;
    margin-top: 40px;
    justify-content: flex-end;
  }

  .action_verify_wrapper {
    margin-top: 16px;
  }

  .input_wrapper {
    display: flex;
    background-color: white;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid #E3E5E8;
    width: 100%;
    max-width: 500px;
    gap: 10px;

    > input {
      border: none;
      width: 100%;
    }
  }

  .resend__wrapper {
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .resend {
    color: #595F82;
    cursor: pointer;
    text-align: end;
  }
}

.error{
  color: #F00;
}

.error.message_verify {
  margin-right: 12px;
}