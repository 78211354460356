@import "../../../assets/styles/mediaSizes";

.dropdown {
  padding: 9px 14px 9px 18px;
  box-sizing: border-box;
  text-transform: uppercase;
  max-width: 200px;
  min-width: 100px;
  text-align: center;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
  height: 32px;
  font-size: 12px;
  font-weight: 800;
  font-family: "greycliffcf-bold-salt";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  @media (max-width: $MOBILE) {
    height: 19px;
    padding: 0 8px;
  }

  &--high {
    height: 44px;
  }

  &--is-active {
    color: white !important;

    .triangle {
      border-color: transparent transparent white transparent !important;
      transform: none !important;
    }
  }

  &__content {
    &_container {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
    }
  }

  &__header {
    &_title {
      font-family: "greycliffcf-extrabold-salt";
      font-size: 14px;
      line-height: 1.21;
      letter-spacing: 0.56px;
      text-align: center;
      color: #4d4d4d;
      text-transform: uppercase;
      margin-top: 15px;

      &_container {
        padding: 16px 0;
        flex-direction: column;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        height: 125px;
        background-color: white;
        width: 100%;
        display: flex;
      }
    }

    &_status {
      margin-top: 5px;
      font-family: "greycliffcf-extrabold-salt";
      font-size: 14px;
      color: #707070;
      text-transform: uppercase;
      display: block;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3.5px 4px 3.5px;
    transform: scaleY(-1);
    margin-left: 8px;
  }

  &__option {
    width: calc(75% + 5px);
    border: 2px solid transparent;
    line-height: 44px;
    color: #8d8d8d;
    border: #707070;
    border-radius: 22px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "greycliffcf-bold-salt";
    margin-bottom: 12px;
    background-color: white;

    &:last-of-type {
      margin-bottom: 50px;
    }
  }

  &__cancel {
    color: #f42862;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "greycliffcf-medium-salt";
    cursor: pointer;
  }

  .dialog-button__dropdown-arrow {
    margin-left: 6px;
    & div div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.arrow-color__orange {
      path {
        fill: #ff6f25 !important;
      }
    }

    &.arrow-color__green {
      path {
        fill: #00c5ae !important;
      }
    }

    &.arrow-color__blue {
      path {
        fill: #00bcf6 !important;
      }
    }

    &.arrow-color__gray {
      path {
        fill: #d1d1d1 !important;
      }
    }

    &.arrow-color__pink {
      path {
        fill: #f42862 !important;
      }
    }

    &.arrow-color__purple {
      path {
        fill: #6244ca !important;
      }
    }

    &.arrow-color__red {
      path {
        fill: #f52d22 !important;
      }
    }
  }
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 2000 !important;
}

.react-confirm-alert-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 325px !important;
  padding: 35px 37px !important;
  box-sizing: content-box;

  font-family: "greycliff-cf-medium" !important;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #777777 !important;

  h1 {
    font-family: "greycliff-cf-medium";
    font-size: 22px;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 8px;
  }
}

.react-confirm-alert-button-group {
  button {
    width: 150px;
    height: 48px;
    border-radius: 36px !important;
    border: solid 2px #f42862 !important;

    font-family: "greycliff-cf-bold";
    font-size: 18px !important;
    line-height: normal;
    letter-spacing: normal;

    &:nth-child(1) {
      background-color: rgba(255, 255, 255, 0) !important;
      color: #f42862;
    }
    &:nth-child(2) {
      background-color: #f42862;
      color: #ffffff;
    }
  }
}

.select-status-backdrop {
  background-color: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.select-status-modal--right {
  left: unset !important;
  right: 0 !important;
}
.select-status-modal {
  z-index: 11;
  min-width: 300px;
  width: 100%;
  position: absolute;
  top: 0;
  left: unset;
  right: 0;
  background-color: white;
  border-radius: 10px;

  .dropdown__header_title_container {
    padding: 16px 0;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 125px;
    background-color: white;
    width: 100%;
    display: flex;
    border-radius: 10px 10px 0 0;
  }

  .dropdown__header_title {
    font-family: "greycliffcf-extrabold-salt";
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.56px;
    text-align: center;
    color: #4d4d4d;
    text-transform: uppercase;
    margin-top: 15px;
    word-break: break-word;
  }

  .dropdown__header_status {
    margin-top: 5px;
    font-family: "greycliffcf-extrabold-salt";
    font-size: 14px;
    color: #707070;
    text-transform: uppercase;
    display: block;
  }

  .dropdown__content_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    background-color: #f0f2f5;
    border-radius: 0 0 10px 10px;
  }
  .activeBorderColor {
    border: 4px solid;
  }
}
