.styled_input {
  border: solid 1px #707070;
  border-radius: 6px;
  background-color: #ffffff;
  width: 327px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
  opacity: 0.8;

  &::placeholder {
    opacity: 0.3;
  }

  &--icon {
    padding-right: 2.3rem;
  }

  &--error {
    border-color: #ff0000;
    border-width: 2px;
  }

  &__error {
    height: 20px;
    width: 100%;
    font-family: "greycliff-cf-medium";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ff0000;
    margin: 5px;
  }

  &__success {
    height: 20px;
    width: 100%;
    font-family: "greycliff-cf-medium";
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: normal;
    color: #33b542;
    margin: 5px;
  }
}
