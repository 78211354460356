.suggest-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 20px;

  &__gameplan {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-right: 16px;
    padding: 10px 23px;

    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    letter-spacing: -0.34px;
    color: #f42862;

    border-radius: 24px;
    border: solid 2px #f42862;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__suggest {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px 31px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    letter-spacing: -0.34px;
    color: #fff;

    border-radius: 24px;
    background-color: #f42862;
    box-sizing: border-box;
    cursor: pointer;
  }
}