.my-profile-suggestion {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;
  padding: 16px;
  border-bottom: 1px solid var(--lightGray5);

  img {
    width: 80px;
    height: 87px;
    border-radius: 8px;
  }
}

.my-profile-suggestion-info-container {
  display: flex;
  gap: 8px;
}

.my-profile-suggestion-info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 345px;
    height: 1.2em;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    color: var(--gray);
  }
}

.my-profile-suggestion-status {
  text-transform: capitalize;
  padding: 0 8px;
  border-radius: 16px;
  height: 24px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.my-profile-avatar-group {
  & > div:first-child {
    width: 24px;
    height: 24px;
    font-size: 13px;
    background-color: var(--primary-50);
    color: #f42871;
  }
}

.my-profile-suggestion-from-outside {
  display: flex;
}
