.suggest-online-details {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  &__top {
    display: flex;
    align-items: center;
  }

  &__meaning {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    line-height: 1.92;
    letter-spacing: -0.26px;
    color: #595f82;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    margin-top: 16px;
  }

  &__button {
    display: flex;
    align-items: center;
    height: 40px;
    margin-right: 8px;
    padding: 11px 24px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: 1.53;
    letter-spacing: -0.3px;
    color: #595f82;

    border-radius: 34px;
    background-color: #f0f2f5;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__app {
    position: relative;
    margin-bottom: 28px;

    &--name {
      position: relative;
      width: 345px;
    }

    &--required {
      position: absolute;
      top: 43%;
      left: 12px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      line-height: 1.35;
      letter-spacing: -0.34px;
      color: #f42862;
      z-index: 10;
    }

    &--input {
      width: 100%;
      height: 40px;
      margin-top: 16px;
      padding: 10px 0 10px 22px;
      border-radius: 8px;
      border: solid 1px #e3e5e8;
      background-color: #fff;
      box-sizing: border-box;

      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      line-height: 1.35;
      letter-spacing: -0.34px;
      color: #222;

      &::placeholder {
        font-family: "greycliffcf-demibold-salt";
        font-size: 17px;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #a9b0b9;
      }
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }

  &__textarea {
    width: 100%;
    height: 84px;
    margin: 12px 0;
    padding: 12px;
    border-radius: 8px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    box-sizing: border-box;
    resize: none;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    line-height: 1.35;
    letter-spacing: -0.34px;
    color: #222;

    &::placeholder {
      color: #a9b0b9;
    }
  }

  &__link-error {
    position: relative;
    margin-top: 10px;
    padding: 10px;
    border: solid 1px #f52d22;
    border-radius: 5px;
    background-color: rgba(245, 45, 34, 0.1);
  }

  &__link-error-close {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }

  &__link-error-reason {
    display: flex;
    font-family: "greycliffcf-regular-salt";
    font-size: 15px;
    color: #f52d22;
  }

  &__link-error-message {
    margin-left: 5px;
  }

  &__link-error-subtitle {
    font-family: "greycliffcf-bold-salt";
    font-size: 10px;
    letter-spacing: -0.2px;
    color: #a9b0b9;
    margin-top: 10px;
  }

  &__link-error-details {
    font-family: "greycliffcf-regular-salt";
    font-size: 15px;
    font-weight: 500;
    font-size: 15px;
    color: #222;
  }

  &__error {
    position: absolute;
    bottom: -20px;
    left: 25px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    line-height: 1.35;
    letter-spacing: -0.34px;
    text-align: left;
    color: #f42862;
  }
}

.suggest-details-info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 12px;
  padding: 12px 12px;

  font-family: "greycliffcf-demibold-salt";
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #222;

  border-radius: 4px;
  background-color: #f0f2f5;
  box-sizing: border-box;

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 10px;
    letter-spacing: -0.2px;
    color: #a9b0b9;
    text-transform: uppercase;
  }
  &__info {
    margin: 15px 0;
  }
  &__info-title {
    font-family: "greycliffcf-bold-salt";
    font-size: 12px;
    text-transform: uppercase;
    color: #a9b0b9;
  }
  &__info-value {
    font-family: "greycliffcf-medium-salt";
    &--link {
      color: #4a8cff;
    }
  }
}

.meeting-info {
  transform: translateY(-90%);
}

.suggest-comment {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  &__textarea {
    width: 100%;
    height: 84px;
    margin: 12px 0;
    padding: 12px;
    border-radius: 8px;
    border: solid 1px #e1e1e1;
    background-color: #fff;
    box-sizing: border-box;
    resize: none;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    line-height: 1.35;
    letter-spacing: -0.34px;
    color: #222;

    &::placeholder {
      color: #a9b0b9;
    }
  }
}