.calendar {
  margin-right: 55px;
  min-height: 245px;
}

.calendar-days__two {
  // margin-left: 52px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  position: relative;
  height: 32px;

  .calendar-header__month-year-display {
    width: 264px;
    text-align: center;

    font-family: "greycliffcf-bold-salt";
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: center;
    color: #222;
    text-transform: uppercase;
  }

  .calendar-header__arrows {
    height: 32px;
    width: 32px;
    border: 1px solid #e3e5e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;

    &:hover {
      border-color: #595f82;
    }

    path {
      fill: #595f82 !important;
    }

    &.calendar-header__arrows-right {
      right: 0;
    }
  }
}

.calendar-days {
  display: flex;
  justify-content: space-between;
}

.calendar-days__row {
  display: flex;
}

.calendar-days__row-item {
  /* margin: 0 7px; */
}

.calendar-days__dates {
  display: grid;
  grid-template-columns: 24px 24px 24px 24px 24px 24px 24px;
  grid-gap: 6px 16px;

  .calendar-days__date {
    border: 1px solid #e3e5e8;
    border-radius: 14px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-family: "greycliffcf-medium-salt";
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #595f82;

    &:hover {
      border-color: #595f82;
    }

    &.selected {
      color: white;
      background-color: #595f82;
      border-color: #595f82;
    }

    &.empty {
      cursor: unset;
      border: none;
      pointer-events: none;
    }

    &.past {
      border-color: transparent;
      color: #d6e0e8;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.calendar-days__header-item {
  font-family: "greycliffcf-medium-salt";
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: #a9b0b9;
  text-transform: uppercase;
}
