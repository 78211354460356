.date-range-container {
  .date-range {
    overflow: auto;
    display: flex;
    gap: 10px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li span {
      word-break: keep-all;
      white-space: nowrap;
    }
  }

  .add-date-range {
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: var(--black2);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}

.time-list {
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  margin-top: 16px;
}
