.extraClassTeeup {
  font-size: 20px !important;
  pointer-events: auto !important;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.extraClass {
  font-size: 20px !important;
  pointer-events: auto !important;
  border-radius: 8px;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

#sortSuggestions-tooltip {
  background-color: #ffffff;
  min-width: 120px;
  padding: 10px 0;
  // padding: 10px 15px;
  display: flex;
  border-radius: 8px;
  opacity: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  z-index: 9999 !important;
}

.teeup_tooltip_container_sort {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}

.tooltip_list_sort {
  width: auto;
  min-width: 120px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &_img {
    margin-left: 10px;
  }

  &:active {
    opacity: 0.5;
  }

  &__title {
    &_teeup {
      font-family: "greycliff-cf-medium";
      font-size: 16px;
      color: #222222;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.32px;
    }
  }
}

.tooltip_list_sort:hover {
  background-color: #f5f8fa;
  // background-color: #f5f5f5;
}
