.suggestion {
  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    // justify-content: space-around;
    align-items: center;
  }

  &__container {
    display: flex;
    // margin-left: 20%;
    width: calc(100vw - 380px);
    height: 90vh;
    justify-content: center;
  }

  &__left-side {
    background-color: #f3f3f3;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    // border-top-left-radius: 8px;
    border-radius: 8px;
    width: 370px;
    min-width: 370px;
    overflow: auto;
    position: relative;
    // top: 44px;
    left: -5px;
    animation-name: left-slide-modal;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-duration: 0.3s;
    z-index: 2;
    height: 100%;
    // padding: 14px 13px;
    box-sizing: border-box;
    -ms-overflow-style: none;
    // scrollbar-width: none;
    overflow: hidden;
  }

  // &__left-side::-webkit-scrollbar {
  //   width: 0;
  // }

  &__right-side {
    background-color: #f3f3f3;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    // border-top-left-radius: 8px;
    width: 380px;
    overflow: auto;
    position: absolute;
    // top: 44px;
    right: 0;
    top: 0;
    animation-name: left-slide-modal;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-duration: 0.3s;
    z-index: 2;
    height: 100%;
    // padding: 14px 13px;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  // &__right-side::-webkit-scrollbar {
  //   width: 0;
  // }
}

.suggestion__left-side::-webkit-scrollbar,
.suggestion__right-side::-webkit-scrollbar {
  width: 0;
}

.teeup {
  &__name-wrapper {
    height: 70px;
  }
  &__name {
    position: absolute;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-family: "greycliff-cf-bold";
    font-size: 24px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__image {
    width: 100%;
    position: absolute;
    z-index: 0;
  }
}

.close__suggestion__modal {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  cursor: pointer;
}
