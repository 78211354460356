.time-picker {
  height: 100%;
  background-color: rgba(244, 40, 98, .2);
  border-left: 1px solid #ff3838;
  border-right: 1px solid #ff3838;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 10;
  &__toggle {
    width: max-content;
    background-color: #fff;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 20px;
    color: #f42862;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    outline: none;
    &-icon {
      margin-right: 5px;
    }
  }
}
