.people-participants-icon {
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 16px;
  object-fit: cover;

  .people-participant-avatar {
    border-radius: 9999px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  .people-participant-avatar-default {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    background-color: var(--primary-50);
    color: #f42862;
    font-weight: 500;
    font-size: 12px;
  }
}

.people-participant-icon-large {
  min-width: 48px;
  height: 48px;
}

.people-participants-organizer {
  position: absolute;
  top: -2px;
  left: 0;
  width: 11px !important;
  height: 11px !important;
}

.people-participants-organizer-small {
  width: 6.5px !important;
  height: 6.5px !important;
}

.people-participant-icon-xl {
  width: 109px !important;
  height: 109px !important;
  font-size: 24px;
}
