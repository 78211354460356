.filtersection {
  padding: 12px 4px 8px 14px;
  border-radius: 8px;
  background-color: #f0f2f5;
  // background-color: #f0f0f0;
  margin-bottom: 4px;

  &__title {
    margin: 0;
    margin-bottom: 8px;
    font-family: "greycliffcf-bold-salt";
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.38px;
    color: #222222;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    box-sizing: border-box;
    height: 24px;
    padding: 5px 16px;
    border-radius: 34px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #767676;
    cursor: pointer;

    &--active {
      background-color: #595f82;
      color: white;
    }
  }
}
