.empty-mentions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  .empty-mentions__title {
    margin: 17px 0 20px 0;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #787f88;
    font-family: "greycliffcf-medium-salt";
  }
  .empty-mentions__description {
    max-width: 268px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #787f88;
    font-family: "greycliffcf-regular-salt";
  }
}
