.suggest-modal__content {
  height: 634px;
  box-sizing: border-box;
  padding: 16px 12px;
  &-where {
    height: 300px;
    overflow-y: auto;
  }
  &.-timeWheel {
    padding: 0;
  }
}
