.extraClassTeeup {
  font-size: 20px !important;
  pointer-events: auto !important;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

#teeup-tooltip {
  // background-color: #ffffff;
  // width: 170px;
  padding: 5px 0px;

  // height: 90px;
  display: flex;
  border-radius: 8px;
  opacity: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  z-index: 9999 !important;
}

// #teeup-tooltip:hover{
//     box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
// }

.teeup_tooltip_container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  background-color: #ffffff;
}

.tooltip_list_item {
  margin: 0px 5px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: flex-end;

  &:active {
    opacity: 0.5;
  }

  &__title_teeup {
    margin-left: 10px;
    font-family: "greycliff-cf-medium";
    font-size: 16px;
    color: #222222;
  }
}

.tooltip-icon {
  width: 16px;
  height: 16px;
}

.three-dots {
  // width: 20px;
  // height: 20px;

  width: 28pt;
  height: 28pt;
  border-radius: 50%;
  margin: -5px -15px;
  z-index: 1;
  object-fit: contain;
}

.three-dots-suggestions{
    // width: 28px;
    // height: 45px;
    width: 28pt;
    height: 28pt;
    position: absolute;
    top: 30%;
    right: 1%;
    z-index: 1;
}

.modal {
    &__wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10px;
        animation-name: left-slide;
        animation-timing-function: ease-out;
        animation-direction: alternate;
        animation-duration: 0.3s;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 400px;
        height: 150px;
        background-color: #ffffff;
        border-radius: 8px;
    }

    &__text {
        padding-top: 15px;
        font-family: greycliffcf-extrabold-salt;
        font-size: 17px;
        color: #222222;
        text-align: center; 
        width: 150px;
        margin: 0 auto;
    }

    &__button {
        font-family: greycliffcf-demibold-salt;
        font-size: 17px;
        color: #f42862;
        width: 160px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 38px;
        border: solid 2px #f42862;
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-top: 15px;
    }
}
