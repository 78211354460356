.conversation {
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  flex-grow: 1;
  // height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 576px;
  // max-width: 491px;

  &.nd-conversation {
    background-color: transparent;
    max-width: 100%;
    height: 100%;

    & > div {
      margin-top: 0;
    }
  }
}

.higlights {
  &__wrapper {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-x: hidden;
  }

  background-color: white;
  width: 400px;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-duration: 0.3s;
  z-index: 2;
  height: 100vh;
  padding: 2vh 0;
}

.close-img-preview {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: rgb(245, 246, 246);
  }
}

.img-preview-modal {
  max-width: 100%;
  max-height: 100%;
}
