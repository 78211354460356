.circle-loader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin: 4px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: circle-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #717171 #d5d4d4 #717171 #717171;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
