.chat {
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 5px;

  &__messages {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    max-height: 400px;
    height: 100%;
    min-height: 300px;
  }

  &__message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    text-align: start;

    &_right {
      align-items: flex-end;
    }
  }

  &__messageContent {
    background-color: var(--lightGray2);
    padding: 5px;
    border-radius: 8px;
    max-width: 80%;
  }

  &__messageRole {
    font-size: 12px;
    margin-bottom: 5px;
    color: #666;
  }

  &__actionsWrap {
    display: grid;
    grid-template-columns: 1fr 100px;
  }

  &__input {
    width: 100%;
    background-color: white;
    border: 1px solid #e3e5e8;
    border-radius: 4px;
    padding: 12px;
  }
}
