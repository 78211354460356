.suggestionSVG {
  width: 11px;
  height: 11px;
  fill: #9d9d9d;
}

.suggestionSVG--mine {
  width: 11px;
  height: 11px;
  fill: rgb(116, 169, 198);
}

.three__dots {
  width: 35px;
  position: absolute;
  top: -15px;
  right: -15px;
  opacity: 0;
}

.gameplan__box:hover .three__dots {
  opacity: 1;
}
