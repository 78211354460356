.tabInvitees {
  width: 33.3%;
  padding: 10px 0;
  cursor: pointer;

  &_active {
    border-bottom: 2px solid #f42862;
  }
  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_title {
    font-family: "greycliffcf-extrabold-salt";
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: -0.24px;
    text-align: center;
    color: #9d9d9d;
    text-transform: uppercase;
    &_active {
      color: #222222;
    }
  }
}
