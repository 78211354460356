.suggestion__popup {
  &--wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 5;
  }

  &__container {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 664px;
    margin: 0.25rem auto;
    border-radius: 16px;
    background-color: #ffffff;
    transform: translateX(-50%);
    overflow: hidden;

    &__body {
      &__scrollbar {
        padding: 12px 0 0 0;
        max-height: calc(100vh - 80px);
        display: flex;
        overflow: auto;

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: none;
          border-radius: 5px;
          transition: background-color 0.2s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__left__side {
    position: relative;
    width: 400px;

    @mixin cardText {
      font-family: "greycliffcf-demibold-salt";
      font-size: 15px;
      font-weight: 600;
    }
    @mixin leftSideButton {
      width: 129px;
      height: 32px;
      border-radius: 38px;
      border: solid 2px #f42862;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
    }

    &--gameplan__container {
      margin: 0 0 12px 0;
      padding-bottom: 8px;
      background-color: #f8fafc;
      border: 1px solid #e3e5e8;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .gameplan__customApp {
        padding: 18px 12px 0;
        &--title {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
        }
        &--text {
          margin-left: 4px;
          @include cardText;
        }
      }

      .customButton {
        &__primary {
          @include leftSideButton;
          @include cardText;
          width: 134px;
          background-color: #f42862;
          color: white;
        }

        &__secondary {
          @include leftSideButton;
          @include cardText;
          background-color: transparent;
          color: #f42862;
        }
        &__copySvg {
          path:last-of-type {
            fill: #f42862;
          }
        }
        &__text {
          margin-left: 5px;
        }
      }

      .buttons_container {
        display: flex;
        margin: 12px 0 16px 0;
        & div + div {
          margin-left: 12px;
        }
      }

      .gameplan__header {
        display: flex;
        align-items: center;
        font-family: "greycliffcf-extrabold-salt";
        font-size: 13px;
        font-weight: 800;
        line-height: 1;
        letter-spacing: 0.59px;
        color: #9d9d9d;

        .suggestion__type {
          flex: 1;
          margin-left: 4px;
        }

        .suggestion__game-plan {
          font-size: 10px;
          color: #00bcf6;
        }
      }

      .gameplan__info {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
      }
    }

    .gameplan__footer__wrapper {
      margin-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__right__side {
    flex: 1;
    &--actions__container {
      box-sizing: border-box;
      padding: 0 0 0 12px;
    }

    &--action {
      box-sizing: inherit;
      padding: 8px 4.2px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.2s ease;

      span {
        padding-top: 4px;
        margin-left: 6.9px;
        font-family: "greycliff-cf-medium";
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: -0.3px;
        color: #595f82;
      }
    }

    &--action:hover {
      background-color: #f0f2f5;

      span {
        color: #222222;
      }
    }
  }
}

.gameplan__footer {
  box-sizing: border-box;
  background-color: #f8fafc;
  border: 1px solid #e3e5e8;
  border-radius: 10px;

  &__header {
    box-sizing: inherit;
    display: flex;
    border-bottom: 1px solid #e3e5e8;
    padding: 0 12px;

    .footer__action {
      padding: 14px 0 11px 0;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      &--text {
        margin-left: 4px;
        padding-top: 2px;
        font-family: "greycliff-cf-medium";
        font-size: 11px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: -0.3px;
        color: #a9b0b9;
        text-transform: uppercase;
      }

      &:first-of-type {
        margin-right: 12px;
      }

      &.active {
        border-bottom: 3px solid #f42862;

        .footer__action--text {
          color: #222222;
        }
      }
    }
  }

  &__body {
    &__inner {
      background-color: #fff;
      margin: 12px 0;
      padding: 12px;
      border: 1px solid #e3e5e8;
      border-radius: 4px;

      &__header {
        padding: 0 0 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e3e5e8;

        &__left-side {
          display: flex;
          align-items: center;

          svg {
            width: 12px;
            height: 12px;
            color: #ffb520;
          }

          span {
            margin-left: 8px;
            font-family: "greycliff-cf-medium";
            font-size: 11px;
            font-weight: 800;
            line-height: 12px;
            letter-spacing: -0.22px;
            color: #787f88;
            text-transform: uppercase;

            &:first-of-type {
              padding-top: 3px;
            }

            &.no-margin {
              margin: 0;
            }
          }
        }
      }

      &__content {
        padding: 12px 0;
      }
    }

    .no-likes,
    .no-dislikes,
    .no-reactions,
    .no-comments {
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .reaction__person__grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      gap: 16px 8px;
    }

    .reaction__person {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        padding-top: 3px;
        font-family: "greycliff-cf-medium";
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.22px;
        color: #767676;
        align-items: center;
      }
    }
  }
}

.comments-tab {
  background-color: transparent;
  border: 0;
  padding: 0;

  .no-comments-img {
    padding-bottom: 12px;
  }
}

.gameplan__content {
  width: 100%;
  height: 285px;
  background-color: #f8fafc;
  border: 1px solid #e3e5e8;
  border-radius: 10px;
  overflow: hidden;
}
