.suggest-online {
  &__app {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.3px;
    text-align: left;
    color: #222;
  }
}