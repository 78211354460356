.people-ask-people-checkbox {
  display: flex;
  align-items: center;

  & > span {
    padding: 0 !important;
  }
}

.people-ask-people-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightGray);
  padding-right: 16px;
}
