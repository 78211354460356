.suggestion-where-map-container {
  border: 1px solid var(--lightGray5);
  border-radius: 4px;
  height: 100%;

  .external-address {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }
  }

  .is-external {
    height: 150px;
  }
}

.suggestion-where-map {
  position: relative;
  width: 100%;
  height: 128px;

  &__button {
    position: absolute;
    bottom: 16px;
    right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 184px;
    height: 32px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 12px;
    letter-spacing: -0.24px;
    text-align: left;
    color: #595f82;

    border-radius: 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: pointer;
    z-index: 6;
    transition: 0.2s all;

    &:hover {
      background-color: #595f82;
      color: #fff;
      box-shadow: none;
    }
  }

  &__marker {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.suggestion-where-map-container-collapsed {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > .suggestion-where-map-container {
    z-index: 1;
    border: none;
  }

  & > div > .suggestion-where-map {
    height: 94%;
  }
}

.suggestion-where-confirm-address {
  z-index: 2;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid var(--lightGray5);
  border-bottom: 0;
  background-color: #fff;
  padding: 24px 16px;
  box-shadow: 0px -2px 7px -1px #a8aaad24;

  & > div {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--lightGray5);
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  p {
    color: var(--gray5);
    font-size: 14px;
    font-weight: 600;
    margin-top: 6px;
  }

  button {
    height: 42px;
    margin-top: 24px;
    width: 100%;
  }
}
