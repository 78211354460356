@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.people-sub-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  & > ul,
  div,
  li {
    animation: fadeInAnimation ease 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.people-participants-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.people-participants-more {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0 5px;
  color: #F42862;
  background: #FDF6F7;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.people-participants-extended {
  margin-top: 8px;
  .people-participants-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}

.people-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.people-sup-skeleton {
  margin-top: 20px;
}
