.createTeeupAiModal {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__slotsWrap {
    padding-left: 10px;
  }

  &__headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }

  &__suggestionWrapper {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 10px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
    margin: 10px 0;
  }

  &__suggestWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 10px;
  }

  &__closeBtn {
    align-self: flex-end;
    background: transparent;
    width: fit-content;
    cursor: pointer;
    border: none;
    margin-right: 5px;
    margin-top: 5px;

    svg {
      path {
        transition: fill .2s ease-out;
      }
    }

    &:hover {
      svg {
        path {
          fill: black !important;
        }
      }
    }
  }

  &__btn {
    padding: 10px 25.4px 10px 25.6px;
    border-radius: 24px;
    background-color: #f42862;
    border: 2px solid #f42862;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;

    &_secondary {
      color: #f42862;
      background-color: #ffffff;
    }
  }

  &__btnWrap {
    text-align: center;
    margin-top: 20px;
  }

  &__actionsWrapper {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__createBtn {
    border: none;
    background-color: transparent;
    border-radius: 36px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 46px;
    outline: none;
    min-width: 110px;
    white-space: nowrap;
    width: 100%;
    background-color: var(--radicalRed);
    color: var(--white);
    font-family: greycliffcf-medium-salt;
    font-size: 18px;
    background-color: var(--black2);
    color: var(--white);
    max-width: 300px;
    margin: 10px auto;

    &:disabled {
      cursor: not-allowed;
      opacity: .4;
    }
  }
}
