#information-tooltip {
  background-color: #222222 !important;
  padding: 7px 12px !important;
  margin: 0 !important;
  display: flex;
  border-radius: 4px !important;
  opacity: 0.7 !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  z-index: 9999 !important;
}

.extraClassInfo {
  pointer-events: auto !important;
  border-radius: 4px !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
    cursor: pointer;
  }
}

.tooltip {
  &_container {
    &_info {
      display: flex;
    }
  }

  &_info {
    &_title {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: flex;
    }
  }
}
