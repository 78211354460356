.suggest-when {
  &--wrapper {
    height: 104px;
    background-color: rgb(235, 235, 235);
  }

  &__card {
    max-width: 320px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 4px 0px, rgba(0, 0, 0, 0.25) 0px 2px 3px;
    padding: 8px;
    margin: 10px;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }
    &-clock {
      width: 11px;
      height: 11px;
    }
    &-nickname {
      color: rgb(157, 157, 157);
      font-family: greycliffcf-bold-salt;
      font-size: 10px;
      margin-left: 5px;
      text-transform: uppercase;
    }
    &-date,
    &-time {
      display: block;
      font-family: greycliffcf-demibold-salt;
      font-size: 17px;
    }
  }
}
