.suggest-online-apps {
  display: flex;
  margin-top: 12px;
}

.suggest-online-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  width: 60px;
  height: 66px;

  cursor: pointer;

  &__title {
    margin-top: 4px;
    font-family: "greycliffcf-medium-salt";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.05px;
    text-align: center;
    color: #989898;
  }
}
