.custom-date-range {
  width: 468px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--gray4);
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .date-range-label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > div {
      width: 100%;
    }
  }

  .date-range-calendar {
    margin-top: 44px;
  }
}

.custom-time {
  button {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
