@import "assets/styles/mediaSizes.scss";

.people-attendance-content {
  width: 430px;

  @media (max-width: $MOBILE) {
    width: 100%;
  }
}

.people-attendance-options-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.people-attendance-button {
  height: 36px;
  font-weight: 600;
}
