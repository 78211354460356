.pill {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--lightGray5);
  background-color: var(--lightGray6);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  img {
    cursor: pointer;
  }
}
.pill--selected {
  border: 1px solid #b5d0f7;
  color: var(--darkBlue);
  background-color: var(-lightBlue3);
  cursor: default;
}

.pill--disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
