.suggestion-item__decided {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    h4 {
      font-weight: 700;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: var(--gray4);
    }
  }

  img {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -40px;
  }
}
