.calendarCard {
  width: 100%;
  position: relative;
  height: calc(100vh - 63px);
    // left: 70,
  overflow: auto;
  background-color: #F0F2F5;
  // backgroundColor: '#f0f0f0';
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  &--lazyDiv {
    height: 15px;
  }
}

.comingUpListHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 6px;
}
// this is a workaround due to react native SectionList's limited styling
.sectionListDivHelperComingUp > div > div > div:first-of-type {
  .comingUpListHeaderContainer {
    margin-top: 0;
  }
}
