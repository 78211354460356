input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 10px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 10px;
}

.checkbox {
  display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
  height: 10px;
  width: 10px;
  background: #fff;
  border: 1px #ddd solid;
  border-radius: 2px;
  margin-right: 4px;
}

.checkmark {
  width: 10px;
  height: 10px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.checkbox--active {
  color: black;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  // .checkbox {
  //   margin-right: 5px;
  // }
}
