.error {
  &__container {
    background-color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    width: 327px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-size: 22px;
    font-family: "greycliffcf-demibold-salt";
    line-height: 26px;
    color: black;
    transform: translateY(-12px);
    text-align: center;
  }

  &__image {
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 15px;
    line-height: 18px;
    color: #777777;
    font-family: "greycliffcf-medium-salt";
    max-width: 295px;
    margin-bottom: 15px;
    text-align: center;
  }

  &__learnmore {
    display: block;
    margin: 30px;
    color: #f53263;
    font-size: 18px;
    line-height: 21px;
    font-family: "greycliffcf-bold-salt";
    cursor: pointer;
  }

  &__button {
    background-color: #f53263;
    box-sizing: border-box;
    line-height: 48px;
    color: white;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    font-family: "greycliffcf-bold-salt";
    font-size: 18px;
    cursor: pointer;
    border: none;

    &--disabled {
      opacity: 0.5;
      cursor: initial;
    }
  }
}
