.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--lightGray);
  padding-bottom: 16px;

  h4 {
    font-size: 18px;
    font-weight: 700;
  }

  span {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    color: var(--gray4);
  }

  button {
    cursor: pointer;
    border-radius: 9999px;
    background-color: var(--lightGray);
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }
}

.people-modal-back {
  cursor: pointer;
  transform: rotate(90deg);
}

.modal-header-title {
  display: flex;
  gap: 12px;
  align-items: center;
}

.modal-bottom-indent {
  border-bottom: 1px solid var(--lightGray5);
}

.hide-border {
  border: 0;
}
