.time-line-container {
  .time-line-label {
    display: flex;
    position: absolute;
    align-items: center;
    gap: 8px;
    top: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--blue3);
    color: var(--white);
    padding: 12px 20px;
    border-radius: 54px;
    cursor: pointer;
    z-index: 100000;
  }

  .time-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // width: 64.5px;
    height: 100%;
    background-color: #256ee81a;
    z-index: 100;
    border-left: 1px dashed var(--blue3);
    border-right: 1px dashed var(--blue3);
    pointer-events: none;
  }

  .static-time-line {
    border-style: solid;
    border-top: 0;
    border-bottom: 0;
  }

  .all-day-time-line {
    top: 50%;
    left: 50%;
    transform: translate(0px, -50%);
  }
}
