.comingUpListItem {
  width: 634px;
  display: flex;
  height: 44px;
  border-radius: 4px;
  padding-left: 4px;
  border: solid 1px #e3e5e8;
  background-color: #fff;
  margin-top: 5px;
  align-items: center;

  &__flag {
    width: 4px;
    height: 36px;
    margin: 0 4px 0 0;
    border-radius: 4px;
    background-color: #8dd0ff;
    align-self: center;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-self: center;
    flex: 1;
  }

  &--text {
    flex-direction: row;
    flex: 1;

    &__title {
      font-family: 'greycliffcf-demibold-salt';
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.3px;
      line-height: 1.47;
      text-align: left;
      color: #494949;
      max-width: 620px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &__time {
      font-family: 'greycliffcf-medium-salt';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.24px;
      line-height: 1.47;
      text-align: left;
      color: #494949;
    }
  }
}