.people-timezone-block {
  margin-top: 16px;
  overflow: hidden;
}

.people-timezone-label {
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray4);
  }
}

.people-timezone-middle-line {
  position: absolute;
  left: 0;
  right: 0;
  top: 59px;
  height: 100%;

  & > div {
    margin-left: auto;
    margin-right: auto;
  }

  & > div:first-child {
    width: 12px;
    height: 12px;
    border-radius: 9999px;
    background-color: #246fe2;
    border: 1px solid #fff;
  }

  & > div:last-child {
    width: 3px;
    background-color: #9bb9e880;
    height: 100%;
  }
}

.people-timezone-items-container {
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.people-timezone-box-night {
  background-color: "#124CAE";
  color: "#FFFFFF";
  border: "1px solid #124CAE";
}
.people-timezone-box-sunrise {
  background-color: "#EDF3FD";
}
.people-timezone-box-sun {
  background-color: "#FFFFFF";
}
.people-timezone-box-sunset {
  background-color: "#EDF3FD";
}
