.suggestion-where-online {
  background-color: #fff;
  margin: -20px;
  margin-top: -20px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .suggest-where-search {
    width: 100%;
    input {
      padding: 8px 12px;
    }
  }
}
