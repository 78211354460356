.input-el {
  width: 100%;
  background-color: var(--white);
  border: 1px solid #e3e5e8;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 17px;
  padding: 11px 45px 11px 12px;

  &::placeholder {
    color: var(--gray) !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &--disabled {
    background-color: transparent;
  }

  &--content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &--mobile {
    border-radius: 67px;
  }

  &--icon {
    padding-left: 45px;
  }

  &--mention {
    padding-left: 27px;
  }

  &--wrapper {
    position: relative;
  }

  &--rounded {
    height: 46px;
    border-radius: 84px;
  }

  &__error-message {
    color: var(--mainRed);
    font-size: 15px;
    margin-top: 10px;
    p:not(:last-child) {
      margin-bottom: 25px;
    }

    &__mobile {
      font-size: 13px;
    }
  }

  &__error-message-mobile {
    font-size: 13px;
  }

  &__loader,
  &__valid,
  &__search,
  &__mention {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &__loader {
    display: flex;
    left: 8px;
  }
  &__valid {
    left: 18px;
  }
  &__search {
    left: 15px;
  }
  &__mention {
    left: 14px;
    &--placeholder {
      opacity: 0.6;
    }
  }

  &__label {
    font-size: 15px;
    margin-bottom: 7px;
  }

  &__password-eye {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: var(--lightGray3);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    &--error {
      justify-content: space-between;
    }
  }

  &--error {
    border-color: var(--mainRed);
  }

  &--hidden {
    display: none;
  }

  &:focus {
    border-color: var(--black);
    box-shadow: var(--blackShadow3);
  }
}

.error-mobiel {
  font-size: 13px;
}
