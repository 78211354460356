@import "assets/styles/mediaSizes.scss";

.people-nudge-content {
  width: 400px;
  @media (max-width: $MOBILE) {
    width: 100%;
  }
}

.people-nudge-option-container {
  margin-top: 8px;
}

.people-nudge-option-item {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray5);
  transition: 0.2s opacity ease;
  cursor: pointer;

  div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    & > div:first-child {
      width: 20px;
      height: 20px;
    }

    span {
      padding-top: 2px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--lightGray5);
  }

  &:last-child {
    padding-bottom: 8px;
  }

  &:hover {
    opacity: 0.75;
  }
}
