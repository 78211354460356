.manage-participants {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 488px;
  height: 488px;

  padding: 7px 11px;

  border-radius: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #222;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__cross {
    height: 36px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 442px;

    margin-top: 8px;

    border-top: 1px solid #f0f2f5;
    box-sizing: border-box;
    overflow-y: auto;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.manage-participants-item {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 62px;

  padding: 11px 9px;

  box-sizing: border-box;

  &__title {
    margin-left: 12px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.33px;
    text-align: left;
    color: #212121;
  }

  &__buttons {
    position: absolute;
    right: 0;

    display: flex;
    align-items: center;

    height: 100%;
  }

  &__delete-user {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    border: 0;
    background-color: #fff;

    cursor: pointer;
  }

  &__recent-invitation {
    display: flex;
    align-items: center;
    height: 40px;

    margin-bottom: 4px;
    padding: 0 12px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;

    border: 0;
    background-color: #fff;
    box-sizing: border-box;

    cursor: pointer;

    &:hover {
      color: #222;
    }
  }

  &__organizer {
    display: flex;
    align-items: center;
    height: 40px;

    margin-bottom: 4px;
    padding: 0 12px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;

    border: 0;
    background-color: #fff;
    box-sizing: border-box;

    cursor: pointer;

    &:hover {
      color: #222;
    }
  }
}

.confirm-delete-user {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 400px;
  height: 200px;

  padding: 7px 50px;

  border-radius: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    width: 300px;
    margin-top: 30px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: center;
    color: #222;
  }

  &__buttons {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  &__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    height: 32px;
    margin-right: 10px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #f42862;
    text-transform: uppercase;

    border: solid 2px #f42862;
    border-radius: 16px;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    height: 32px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #f42862;
    text-transform: uppercase;

    border: solid 2px #f42862;
    border-radius: 16px;
    background-color: #f42862;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.invitation-sent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 330px;
  height: 180px;

  padding: 7px 11px;

  border-radius: 16px;
  background-color: #fff;
  box-sizing: border-box;

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }

  &__title {
    width: 200px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #222;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 40px;
    margin-top: 20px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -0.34px;
    text-align: center;
    color: #fff;

    border: 0;
    border-radius: 24px;
    background-color: #f42862;

    cursor: pointer;
  }
}
