.addinvitees {
  &__modal {
    box-sizing: border-box;
    width: 400px;
    min-height: 424px;
    border-radius: 8px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }

  &__overlay {
    background-color: #00000066;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  &__header {
    background-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #eeeeee;
    padding: 5px 0;
    box-sizing: border-box;
  }

  &__tag {
    &--wrapper {
      flex-direction: row;
      align-items: center;
      height: 33;
      padding: 8px 12px;
      margin-right: 8px;
      margin-top: 8px;
      border-radius: 10px;
      background-color: #f0f2f5;
      box-sizing: border-box;
    }

    &--text {
      margin-left: 6px;
      color: "#767676";
      font-family: "greycliffcf-demibold-salt";
    }
  }
}

.inviteNd-contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  &__header {
    display: flex;
    align-items: center;
    height: 32px;
  }

  &__input {
    width: 100%;
    margin-left: 6px;
    border: 0;
    box-sizing: border-box;

    &::placeholder {
      font-family: "greycliffcf-regular-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: -0.26px;
      text-align: left;
      color: #a9b0b9;
    }

    &--wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-right: 8px;
      padding: 10px 10px 9px 10px;
      border-radius: 16px;
      background-color: #fff;
      box-sizing: border-box;
    }

    &--search {
      cursor: pointer;
    }
  }

  &__tags {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    height: 100%;
    margin-right: 8px;
    border-radius: 16px;
    background-color: #fff;
    cursor: pointer;

    &--active {
      background-color: #e3e5e8;
    }
  }

  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-left: 7.5px;
    font-family: "greycliffcf-bold-salt";
    font-size: 10px;
    font-weight: 800;
    color: #222;
    border-radius: 50%;
    background-color: #f0f2f5;

    &--active {
      background-color: #fff;
    }
  }

  &__earth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  &__sort {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 82px;
    height: 100%;
    border-radius: 16px;
    background-color: #fff;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    cursor: pointer;
    transition: height 0.2s;
    z-index: 15;

    &--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 72px;
      height: 100%;
      border-radius: 16px;
      background-color: #fff;
      font-family: "greycliffcf-demibold-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: -0.26px;
      text-align: left;
      color: #595f82;
      cursor: pointer;
      transition: height 0.2s;
      z-index: 15;
    }

    &--active {
      height: 300px;
    }
  }

  &__notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;

    &--text {
      font-family: "greycliffcf-bold-salt";
      font-size: 20px;
      font-weight: 800;
      color: #e3e5e8;
    }
  }

  &__container {
    height: 505px;
    margin-top: 17px;
    overflow: auto;

    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px; /* ширина scrollbar'a */
      background: transparent; /* опционально */
    }
  }
}

.form-control {
  border: none !important;
  height: 30px !important;
  padding-left: 38px !important;
}

.flag-dropdown {
  border: none !important;
  background-color: transparent !important;
}

.flag {
  transform: scale(1.5) !important;
}

.flag > .arrow {
  display: none !important;
}

.sortNd {
  position: relative;
  z-index: 999;
  width: 200px;
  height: 300px;
  border-radius: 16px;
  background-color: #fff;

  &__item {
    width: 100%;
    height: 60px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    cursor: pointer;
  }
}

.sortNd-dropdown {
  position: absolute;
  width: 100%;
  bottom: -115px;
  padding: 6px 0;
  box-sizing: border-box;
  height: max-content;
  background-color: #fff;
  border-radius: 16px;
  z-index: -2;
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.11);

  &__item {
    width: 100%;
    // height: 32px;
    padding: 6px 11px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: -0.26px;
    text-align: left;
    color: #595f82;
    cursor: pointer;
    transition: height 0.2s;
    z-index: 15;
    box-sizing: border-box;
  }
}

.contactsFilterModal {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: 608px;
    min-height: 340px;
    // max-height: 70%;
    // overflow: scroll;
    position: relative;
    box-sizing: border-box;
    padding: 16px 16px 28px 16px;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .expanded {
    height: 500px;
  }
}

.conactsFilterModal--close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.buttons__container {
  width: calc(100% - 36px);
  height: 92px;
  // position: absolute;
  // bottom: 0;
  // border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;

  .bottom__btn {
    width: 108px;
    height: 36px;
    border-radius: 34px;
    cursor: pointer;
    font-family: "greycliffcf-demibold-salt";
    background-color: #ffffff;
    border: solid 2px #f42862;
    color: #f42862;
  }

  .red {
    margin-left: 8px;
    color: #ffffff;
    background-color: #f42862;
  }
}

.container-title {
  height: 24px;
  padding-bottom: 4px;
  font-family: "greycliffcf-bold-salt";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: #222222;
}

.sortBy__container {
  padding-bottom: 28px;
}

.filterByTags__container {
  width: calc(100% - 32px);
  min-height: 110px;
  // position: relative;
  // overflow: hidden;

  .customTags__container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f2f5;
    // border-bottom: 1px solid #f0f0f0;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: inset 0px 11px 6px -10px #f0f2f5,
      inset 0px -11px 6px -10px #f0f2f5;
    // box-shadow: inset 0px 11px 6px -10px #f0f0f0, inset 0px -11px 6px -10px #f0f0f0;

    // overflow: hidden;
    // max-height: 90px;
  }

  // .customTags-expanded__container {
  //   height: 260px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   overflow: hidden;
  // }

  .customTags__container::-webkit-scrollbar {
    width: 4px;
  }

  .customTags__container::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
  }
}

.viewAll__button__container {
  width: 90px;
  height: 33px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 34px;
  border: solid 2px #f42862;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.54;
  letter-spacing: -0.26px;
  color: #f42862;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 8px;
  right: 0;

  .viewAll__button-text {
    font-family: "greycliffcf-demibold-salt";
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    letter-spacing: -0.26px;
    color: #f42862;
  }
  .viewAll__button-arrow {
    width: 6px;
    height: 11px;
    margin: 0 0 3px 8px;
    color: #f42862;
  }
}

.viewAll-hidden {
  display: none;
}

.contact-filtersNd {
  // width: 616px;
  min-height: 148px;
  margin: 12px 0 4px;
  padding: 12px 12px 15px;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: -0.34px;
    text-align: left;
    color: #222;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    font-family: "greycliffcf-bold-salt";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: right;
    color: #595f82;
    cursor: pointer;

    &:first-of-type {
      margin-right: 25px;
    }
  }

  &__bridge {
    width: 100%;
    height: 1px;
    margin-top: 13px;
    background-color: #e3e5e8;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
  }
}

.filter-tagNd {
  border-radius: 16px;
  background-color: #f0f2f5;
  box-sizing: border-box;
  cursor: pointer;

  &__text {
    padding: 9px 13px 9px 16px;
    font-family: "greycliffcf-regular-salt";
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.24px;
    text-align: left;
    color: #787f88;
  }
}
