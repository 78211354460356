.people-participant-item {
  cursor: pointer;
  display: flex;
  gap: 8px;
  font-size: 13px;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--lightGray5);
  }
}

.people-participant-info {
  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  span {
    font-weight: 700;
    color: var(--gray4);
  }
}

.people-participant-last-action {
  margin-top: 8px;
  color: var(--gray);
  font-weight: 500;
}
