.reset_password {
  &__title {
    font-family: "greycliff-cf-medium";
    font-weight: 600;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  &__requirements_list {
    opacity: 0.8;
    width: 100%;
  }

  &__subtitle {
    font-family: "greycliff-cf-medium";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #777777;
    margin-bottom: 20px;
  }

  &_button {
    background-color: #f53263;
    box-sizing: border-box;
    line-height: 48px;
    color: white;
    text-align: center;
    border-radius: 6px;
    width: 100%;
    font-family: "greycliffcf-bold-salt";
    font-size: 18px;
    cursor: pointer;
    border: none;

    &--disabled {
      opacity: 0.5;
      cursor: initial;
    }
  }

  &--error {
    margin-top: 10px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: normal;
    letter-spacing: normal;
    color: #ff0000;
  }
}
