.suggest-modal__sub-header {
  height: 92px;
  padding: 12px;
  background-color: #f0f2f5;
  box-sizing: border-box;
}

.suggest-modal-pinboard {
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 68px;

  padding: 8px 0 8px 12px;

  border-radius: 8px;
  border: solid 1px #e3e5e8;
  background-color: #fff;
  box-sizing: border-box;

  font-family: "greycliffcf-demibold-salt";
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #222;

  &__header {
    display: flex;
    align-items: center;

    font-family: "greycliffcf-bold-salt";
    font-size: 10px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.17px;
    text-align: left;
    color: #a9b0b9;
    text-transform: uppercase;
  }
}
