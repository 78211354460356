.mycontact {
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 12px 16px;

    &:hover {
      background-color: #f5f8fa;
      // background-color: #f5f5f5;
    }
  }
  
}

.mycontact-tag {
  font-family: "greycliffcf-regular-salt";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #787f88;

  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }
}

.on--hover {
  &__bar {
    display: flex;
    align-items: center;
    visibility: hidden;
  }
  &__action {
    display: flex;
    padding: 8px 12px;
    cursor: pointer;
    .action__image {
      margin-right: 4px;
    }
    .action__button {
      border: none;
      outline: none;
      font-family: "greycliff-cf-medium";
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.26px;
      color: #f42862;
      cursor: pointer;
      background: none !important;
    }
  }
}

.tagNd {
  font-family: "greycliffcf-regular-salt";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #787f88;

  &__wrapper {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &__image {
    width: 10px;
    height: 10px;
    object-fit: contain;
    margin-right: 3px;
    margin-top: 3px;
  }

  &__content {
    display: flex;
    margin-top: 2px;
  }
}

.mycontact__row:hover .on--hover__bar {
  visibility: visible;
}
