.clickable {
  &__row {
    background-color: white;
    height: 50px;
    border-bottom: solid 1px #f0f2f5;
    // border-bottom: solid 1px #f0f0f0;
    display: flex;
    align-items: center;

    &_switch {
      margin-right: 20px;
    }

    &_img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 28%;
  }

  &__button {
    font-family: "greycliff-cf-medium";
    font-size: 15px;
    cursor: pointer;
    border: none;
    &_cancel {
      background-color: #ffffff;
      color: #f42862;
    }
    &_done {
      padding: 8px 17px;
      border-radius: 16px;
      background-color: #f42862;
      color: #ffffff;
    }
  }

  &__input {
    border-radius: 4px;
    border: solid 1px #e3e5e8;
    // border: solid 1px #e4e4e4;
    background-color: #ffffff;
    height: 25px;
    width: 63%;
    padding: 5px;
    &::placeholder {
      font-family: "greycliff-cf-medium";
      font-size: 15px;
      color: #9d9d9d;
    }
  }

  &__title {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: #212121;
    min-width: 124px;
    &_switch {
      font-family: "greycliffcf-demibold-salt";
      font-size: 15px;
      color: #222222;
      min-width: 120px;
    }
  }

  &__value {
    font-family: "greycliffcf-medium-salt";
    font-size: 15px;
    // line-height: 16px;
    letter-spacing: -0.3px;
    color: #767676;
    // color: #9d9d9d;
    &_accountDetails {
      &_value {
        font-family: "greycliffcf-demibold-salt";
        font-size: 15px;
        letter-spacing: -0.3px;
        color: #212121;
      }
    }
  }

  &__action {
    font-family: "greycliffcf-medium-salt";
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: #f42862;
    cursor: pointer;
    &--link{
      color: #595f82;;
    }
    &--link_background{
      background: #595f82;;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85%;
  }
}

.settings-connection-details {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #f0f2f5;
  // border: solid 1px #f0f0f0;
  // background-color: #f5f8fa;
  // background-color: #f5f5f5;
  &-button {
    width: 200px;
    height: 36px;
    cursor: pointer;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #f42862;
    text-transform: capitalize;
    background-color: #ffffff;
    border-radius: 34px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
    border: none;
    margin-right: 8px;
  }
}

.profile__container {
  flex: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow-y: scroll;
  height: 90vh;
}

.profile__container::-webkit-scrollbar {
  width: 0;
}

.public__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 16px;
}

.header__container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    line-height: 24px;
  }

  div {
    padding-left: 16px;
    color: #767676;
  }
}

.clickable-row-edit-mode {
  flex-direction: column;
  border-bottom: 1px solid #E3E5E8;
}

.clickable-row__container {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;

  > div {
    &:first-child {
      .subtitle {
        width: fit-content;
      }

      .clickable-value {
        margin-left: 12px;
        font-weight: 600;
        color: #9d9d9d;
      }
    }
  }

  > div:last-child {
    .subtitle {
      color: #595F82;
      cursor: pointer;
    }

    .remove-button {
      margin-left: 24px;
    }

    .change-mode-wrapper {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        input {
          margin-bottom: 12px;
          font-family: 'greycliff-cf';
          background-color: transparent;
          border-radius: 8px;
          padding: 10px 12px;
          width: 96%;
          font-size: 18px;
          font-weight: 500;
          line-height: 21.6px;
          color: #222222;
          border: 1px solid #E3E5E8;
        }

        textarea {
          border-radius: 8px;
          border: 1px solid #E3E5E8;
          font-family: 'greycliff-cf';
          color:#A9B0B9;
          line-height: 21.6px;
          font-weight: 500;
          font-size: 18px;
          padding: 10px 12px;
          width: 96%;
          height: 108px;
          resize: none;
          margin-bottom: 12px;
        }

        .caption {
          line-height: 15.6px;
          color: #9D9D9D;
        }
      }

      .buttons-wrapper {
        padding-top: 32px;

        button {
          border: none;
          border-radius: 84px;
          height: 28px;
          cursor: pointer;
        }

        .cancel-button {
          background: transparent;
          color: #F42862;
          width: 115px;
        }

        .save-button {
          background: #F42862;
          color: white;
          width: 110px;
        }
      }
    }
  }
}

.verify-modal__wrapper {

  .titel-info {
    color: #767676;
  }

  .verification-method-value__wrapper {
    padding: 10px 12px;
  }

  .input_wrapper {
    display: flex;
    background-color: white;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid #E3E5E8;
    width: 100%;
    max-width: 500px;
    gap: 10px;

    > input {
      border:none;
      width: 100%;
    }
  }

  .resend__wrapper {
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .resend {
    color: #595F82;
    cursor: pointer;
    text-align: end;
  }
}

.error{
  color: #F00;
}

.error.message_verify {
  margin-right: 12px;
}

.error.public_profile {
  margin-bottom: 12px;
}
