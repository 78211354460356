.modal-participant-details-options {
  margin: 16px -16px 0;
  padding: 0 16px;

  border-top: 1px solid var(--lightGray5);
}

.modal-participant-details-option-item {
  padding: 16px !important;
  margin-left: -16px;
  margin-right: -16px;
}
