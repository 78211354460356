.success {
  &__container {
    background-color: white;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__content {
    width: 327px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-size: 22px;
    font-family: "greycliffcf-demibold-salt";
    line-height: 26px;
    color: black;
    transform: translateY(-12px);
    text-align: center;
  }

  &__image {
    margin-bottom: 30px;
    @media (min-width: 960px) {
      height: 200px;
    }
  }
  &__subtitle {
    font-size: 15px;
    line-height: 18px;
    color: #777777;
    font-family: "greycliffcf-medium-salt";
    max-width: 295px;
    margin-bottom: 15px;
    text-align: center;
  }
}
