.people-column-item {
  position: relative;
  display: flex;
  align-items: center;
  transition: all .2s;

  width: 100%;
  height: 56px;

  padding: 0 8px;

  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #f0f2f5;
  }

  &__avatar {
    position: absolute;
    width: 40px;
    height: 40px;

    border: solid 2px #fff;
    border-radius: 50%;

    object-fit: cover;
    object-position: center;
    box-sizing: border-box;
    z-index: 1;

    &--wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      border: solid 2px #fff;
      border-radius: 50%;
      background-color: #f0f2f5;
      box-sizing: border-box;
    }

    &--organizer {
      position: absolute;
      top: -6px;
      left: -6px;
      z-index: 0;
    }

    &--empty {
      font-family: "greycliffcf-demibold-salt";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.2px;
      text-align: left;
      color: #222;
      text-transform: uppercase;
    }
  }

  &__status {
    position: absolute;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 16px;
    height: 16px;

    border-radius: 50%;
    border: solid 2px #fff;
    box-sizing: border-box;
    z-index: 2;

    &--icon {
      width: 8px;
      height: 8px;

      object-fit: contain;
      object-position: center;
      z-index: 5;
    }
  }

  &__introduce {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 8px;

    font-family: "greycliffcf-regular-salt";
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    text-align: left;
    color: #a9b0b9;
  }

  &__name {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.3px;
    text-align: left;
    color: #212121;
  }

  &__dots {
    position: absolute;
    right: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 28px;
    height: 28px;

    border: solid 1px transparent;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all .2s;

    cursor: pointer;

    &:hover {
      border: solid 1px #e3e5e8;
      background-color: #fff;
    }

    &--icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
