.people-details-tab-wrapper {
  margin-top: 20px;
  height: 350px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.people-details-tab-content {
  padding-top: 20px;
  width: 370px;
  display: flex;
  flex-direction: column;
}

.people-details-modal-tabs-actions {
  display: flex;
  gap: 8px;
  margin-top: -8px;
}

.people-details-suggestion-item {
  height: 70px !important;
  min-height: 70px !important;
}

.people-details-tab-content-divider {
  margin-bottom: 40px;
  margin-left: 35px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
}

.people-details-suggestion-item-wrapper {
  margin-bottom: 40px;
}
