.teeup-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 30px;
  margin-top: 15px;
  border-radius: 15px;
  border: solid 1px #f42862;
  background-color: #fff;
  font-family: "greycliffcf-demibold-salt";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: left;
  color: #f42862;
  box-sizing: border-box;
  transition: border 0.2s;

  &:hover {
    border: solid 2px #f42862;
  }

  &--loading {
    background-color: #edeef0;
    color: #a9b0b9;
    border: solid 1px #edeef0;

    &:hover {
      border: solid 2px #edeef0;
    }
  }

  &__spinner {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top: 3px solid #fff;
    background-color: transparent;
    animation: 1s linear 0s infinite reverse circle_spinner;
    box-sizing: border-box;
  }

  &__loader {
    width: 17px;
    height: 17px;
    margin-right: 6px;
    border-radius: 50%;
    border: 3px solid #d0d4d9;
    border-top: 3px solid #a9b0b9;
    background-color: transparent;
    animation: 1s linear 0s infinite reverse circle_spinner;
    box-sizing: border-box;
  }
}

.teeups__container {
  min-height: 132px;
  // border: 1px solid #e3e5e8;
  box-shadow: unset;
  box-sizing: border-box;
  will-change: box-sizing;
  margin-right: 5px;

  &:hover {
    box-shadow: 0 2px 0 0 #e3e5e8;
  }

  &.invited {
    border: 2px solid #fc766c;
    background-color: #fc766c;
    // border-left: none;
    &:hover {
      border-color: #fc766c;
      box-shadow: 0 2px 0 0 #fc766c;
    }
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
}
@keyframes circle_spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}

.reJoinWrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.textReJoinWrapper {
  padding: 5px 0px;
  margin: 8px 0px;
  background-color: white;
  width: 100%;
  border-radius: 70px;

  > span {
    margin-left: 20px;
  }
}

.buttonReJoinWrapper {
  width: 110px;
  cursor: pointer;
}
