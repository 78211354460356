.modal-info-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.modal-info {
  position: relative;
  min-width: 300px;
  max-width: 400px;
  min-height: 120px;
  padding: 35px 25px 25px 25px;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.55);
  animation: showModal 0.3s ease-in forwards;

  @keyframes showModal {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(0);
    }
  }

  &__inner {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    h2 {
      padding-bottom: 16px;
      font-family: "greycliff-cf-bold";
      font-size: 20px;
      text-align: center;
      color: #222;
      line-height: 24px;
      letter-spacing: -0.47px;
      white-space: pre;
    }

    p {
      padding-bottom: 28px;
      font-family: "greycliffcf-demibold-salt";
      font-size: 17px;
      text-align: center;
      color: #8995a4;
      line-height: 21px;
      letter-spacing: -0.47px;
    }
  }
  .modal-btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      transform: scale(1.2);
      transition: filter 0.2s ease-out;
    }

    &:hover {
      img {
        filter: contrast(0);
      }
    }
  }
  .modal-btn-primary {
    display: block;
    padding: 8px 42px;
    font-family: "greycliffcf-demibold-salt";
    color: #fff;
    font-size: 17px;
    border-radius: 34px;
    background-color: #f42862;
    cursor: pointer;
  }
  .modal-btn-secondary {
    display: block;
    padding: 8px 42px;
    font-family: "greycliffcf-demibold-salt";
    color: #f42862;
    font-size: 17px;
    border: 2px solid #f42862;
    border-radius: 34px;
    background-color: transparent;
    cursor: pointer;
  }
  .modal-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
