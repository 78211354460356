button.reaction-button,
button.my-reaction-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  opacity: .4;

  &.active {
    opacity: 1;
  }

  &:disabled {
    opacity: .4;
  }
}

.reaction-number {
  color: var(--gray6);
  font-weight: 600;
  margin-left: 5px;
}

.reaction-button {
  &.inactive {
    border: 0;
    box-shadow: none;
    cursor: not-allowed;
  }
  &.active.inactive {
    border: 0;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.my-reaction-button {
  &.inactive {
    border: 0;
    box-shadow: none;
    cursor: not-allowed;
  }
  &.active.inactive {
    border: 0;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.button-wrapper {
  position: relative;

  .loader-wrapper {
    width: 23px;
    height: 16px;
    border-radius: 6px;
    z-index: 2;
  }

  .loader {
    width: 16px;
    height: 16px;
    border: 1.5px solid #a9b0b9;
    border-bottom-color: #595f82;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
