@import "assets/styles/mediaSizes.scss";

.suggest-where-modal {
  width: 700px;
  height: 85vh;
  margin: 0 -16px;
  background-color: var(--lightGray);
  position: relative;
  overflow: auto;
  max-height: 100vh;

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  @media (max-width: $TABLET_L) {
    width: auto;
  }
}

.suggest-where-tabs {
  background-color: #fff;
}

.suggest-where-modal-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
