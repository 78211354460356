.current-time-pin-container {
  position: absolute;
  top: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100000;
  //   justify-content: center;
  & > div:first-child {
    width: 7px;
    height: 7px;
    background-color: var(--blue3);
    border-radius: 9999px;
  }
  & > div:last-child {
    width: 1px;
    height: 100px;
    background-color: var(--blue3);
    border-radius: 9999;
  }
}
