.create {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
  width: 100%;
  height: 100vh;
  // margin-top: 500px;

  &__input {
    height: 64px;
    border: none;
    border-radius: 8px;
    // border-bottom-right-radius: 8px;
    // border-bottom-left-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    font-size: 24px;
    line-height: 25px;
    font-family: "greycliffcf-demibold-salt";
    letter-spacing: -0.6px;
    padding: 25px 50px 39px 8px;

    &::placeholder {
      color: #b2b2b2;
    }

    &--wrapper {
      position: relative;
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;
      img {
        position: absolute;
        top: calc(50% - 26px);
        right: calc(50% - 26px);
        cursor: pointer;
      }
    }
  }

  &__top {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__align-items {
    display: flex;
    align-items: center;
  }

  &__modal--window {
    height: fit-content;
    position: absolute;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // left: 50%;
    width: 50%;
    // width: 864px;
    border-radius: 12px;
    padding: 4px;
    padding-bottom: 3px;
    max-height: calc(100vh - 88px);
    min-height: 539px;
  }

  .settings__option {
    padding-right: 7px;

    svg {
      width: 15px;
      height: 17px;
      fill: #d6d6d6;
    }

    &-text {
      color: #9d9d9d;
      font-family: "greycliffcf-medium-salt";
      font-size: 17px;
    }

    &-big {
      height: 122px;
      cursor: initial;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      font-family: "greycliffcf-demibold-salt";
      color: #f42862;

      span {
        cursor: pointer;
      }

      div {
        span:first-child {
          color: #222222;
          font-size: 15px;
          margin-right: 10px;
        }

        span:last-child {
          color: #9d9d9d;
          font-size: 13px;
        }
      }
    }
  }

  .settings__title {
    width: 100%;
    color: #222;
    font-size: 17px;
    font-family: "greycliffcf-bold-salt";
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 14px 0;
  }

  &__text {
    padding: 5px 17px;
    box-sizing: border-box;
    color: #767676;
    font-size: 13px;
    font-family: "greycliffcf-regular-salt";
    min-height: 44px;

    span {
      line-height: 16px;
    }
  }

  &__suggest {
    color: #f42862;
    font-size: 17px;
    font-family: "greycliffcf-demibold-salt";
  }

  &__invitation {
    font-size: 17px;
    // width: 62.5%;
    width: 467px;
    margin-left: 4px;
    &--message {
      padding: 10px 0;
      color: #b2b2b2;
      font-family: "greycliffcf-medium-salt";
      line-height: 22px;
      width: 100%;
      height: 362px;
      border: none;
      outline: none;
      resize: none;
      font-size: 17px;
      &::placeholder {
        color: #b2b2b2;
      }
    }
    &--title {
      font-family: "greycliffcf-extrabold-salt";
      color: #222;
      line-height: 20px;
      letter-spacing: -0.34px;
      padding: 12px 0;
      border-bottom: 1px solid rgb(240, 240, 240);
    }
  }

  .plan {
    background-color: white;
    margin: 0;
    border-top: none;
    border-bottom: 1px solid #e3e5e8;
    // border-bottom: 1px solid #E4E4E4;
    cursor: pointer;

    &__title {
      font-family: "greycliffcf-demibold-salt";
    }

    svg {
      width: 15px;
      height: 17px;
      fill: #d6d6d6;
    }
  }

  &__gameplan--wrapper {
    overflow: hidden;
  }

  &__block {
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0 12px;
    border-radius: 8px;
  }

  &__bottom {
    height: 80px;
    border-top: 1px solid #f0f2f5;
    // border-top: 1px solid #F0F0F0;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
  }

  &__button {
    box-sizing: border-box;
    padding: 11px 47px;
    line-height: 22px;
    border-radius: 24px;
    text-align: center;
    font-size: 16px;
    font-family: "greycliffcf-medium-salt";
    cursor: pointer;

    &:first-child {
      margin-right: 12px;
      color: #f42862;
      border: 2px solid #f42862;
    }

    &:last-child {
      background-color: #f42862;
      border: 2px solid #f42862;
      color: white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    .upload-input {
      width: 100%;
      height: 100%;
      right: 0;
    }
  }

  &__invited {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    margin-right: auto;
  }

  &__user {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &-avatar {
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      margin-top: 4px;
    }

    &-name {
      color: #222222;
      font-size: 12px;
      font-family: "greycliffcf-medium-salt";
      width: 50px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-align: center;
    }

    &-manual {
      background-color: rgb(225, 225, 225);
      color: rgb(76, 76, 76);
      font-size: 17px;
      font-family: "greycliffcf-medium-salt";
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
  }
}

.people__invite {
  margin-bottom: 4px;
  padding-bottom: 16px;
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    display: block;
  }
  &--button {
    color: #f42862;
    border: 2px solid #f42862;
    border-radius: 24px;
    padding: 12px 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;
    margin-left: 8px;
  }
  &--overlap {
    margin-top: 8px;
    width: 100%;
  }
  &--count {
    color: #b2b2b2;
    font-size: 15px;
    line-height: 20px;
    font-family: "greycliffcf-demibold-salt";
    margin-left: 3px;
  }
}

.upload-input {
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.svg-wrapper {
  div {
    height: 14px;
  }
}

.createTeeup {
  &_icon {
    display: inline-block;
    & path {
      fill: #9d9d9d;
    }
  }
}

.tag-selected {
  path {
    fill: rgb(245, 246, 246);
  }
}

// .next-fullHeight + div {
//   height: 93%;
// }

.addContact {
  position: fixed;
  z-index: 5;
  height: 100vh;
  // background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  width: 400px;

  &__fromCreateTeeup {
    background-color: none;
  }

  &__content {
    width: 400px;
    background-color: white;
    border-top: 3px solid #f42862;
    position: fixed;
    z-index: 5;
    bottom: 0;
    &__fromCreateTeeup {
      width: calc(100% + 8px);
      background-color: white;
      border-top: 3px solid #f42862;
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: -4px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  &__button {
    cursor: pointer;
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }

  &__text {
    font-family: "greycliffcf-demibold-salt";
    color: black;
    margin: auto;
  }

  &__image {
    height: 15px;
  }
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.shevron-wrapper {
  width: 10px;
  height: 13px;

  path {
    fill: #d6d6d6;
  }
}

.block {
  border-radius: 10px;
  background-color: white;

  &__settings {
    box-sizing: border-box;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-top: 4px;
    cursor: pointer;
  }
}

.location {
  &__input {
    height: 44px;
    border: solid 1px #e1e1e1;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 0 12px 40px;
    font-family: "greycliff-cf-medium";
    letter-spacing: -0.34px;
    color: #222222;
    font-size: 17px;

    &--wrapper {
      height: 75px;
      box-sizing: border-box;
      padding: 14px 16px 18px;
      border-top: 1px solid #f0f2f5;
      // border-top: 1px solid #f0f0f0;
      position: relative;

      &::after {
        content: "";
        opacity: 0.08;
        height: 4px;
        left: 0;
        width: 100%;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
        position: absolute;
        bottom: 0;
      }
    }
  }

  &__suggest {
    box-sizing: border-box;
    height: 60px;
    padding: 12px 16px;
    border: solid 1px rgba(112, 112, 112, 0.16);
    display: flex;
    cursor: pointer;
  }

  &__suggest:hover {
    background: #f0f2f5;
    // background: #f0f0f0;
  }

  &__name {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    letter-spacing: -0.34px;
    color: #4b4b4b;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 17px;
    max-width: 99%;
    display: block;
    white-space: nowrap;
  }

  &__description {
    font-family: "greycliff-cf-medium";
    font-size: 12px;
    letter-spacing: -0.24px;
    color: #989898;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 99%;
    white-space: nowrap;
    display: block;
  }

  &__icon {
    position: absolute;
    min-width: 17px;
    min-height: 17px;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__image {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--wrapper {
      position: relative;
      margin-right: 13px;
      background-color: #f42862;
      width: 33px;
      height: 33px;
      border-radius: 50%;
    }
  }

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  &__list {
    max-height: 500px;
    // width: 100%;
    // position: absolute;
    // z-index: 1;
    // background-color: #ffffff;
  }
}

.invitees__title {
  color: #222;
  font-size: 20px;
  font-family: "greycliffcf-bold-salt";
  line-height: 21px;
  letter-spacing: -0.4px;
  margin: auto;
}

.footer-buttons {
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 12px 0;
    z-index: 2;
    background: #ffffff;
  }

  &__place {
    height: 48px;
    padding: 12px 32.7px 16px 33.3px;
    border-radius: 24px;
    border: solid 2px #f42862;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: #f42862;
  }

  &__suggest {
    padding: 12px 37.4px 16px 37.6px;
    border-radius: 24px;
    background-color: #f42862;
    border: none;
    outline: none;
    cursor: pointer;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
}

.footer-button__active {
  background-color: #f42862;
  color: #ffffff;
}
.footer-button__place-disabled {
  padding: 12px 37.4px 16px 37.6px;
  border-radius: 24px;
  border: solid 2px #f0f2f5;
  // border: solid 2px #f0f0f0;
  outline: none;
  cursor: pointer;

  font-family: "greycliffcf-demibold-salt";
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.footer-button__disabled {
  padding: 12px 37.4px 16px 37.6px;
  border-radius: 24px;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: "greycliffcf-demibold-salt";
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  background-color: #f0f2f5;
  // background-color: #f0f0f0;
  color: rgba(0, 0, 0, 0.6);
}
