.input-with-icon {
  width: 100%;
  display: flex;
  align-items: center;

  .icon {
    width: 14px;
    margin: 0 0 0 12px;
    position: absolute;
    color: #a9b0b9;
  }

  .input {
    width: 100%;
    border: none;
    padding-left: 38px;
    text-align: left;
    max-height: 32px;
    height: 32px;
    border-radius: 20px;
    color: #a9b0b9;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.26px;
  }
}
