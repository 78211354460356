.date-picker-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    display: flex;
    align-items: center;
    color: rgb(34, 34, 34);
    font-size: 14px;
    font-family: greycliffcf-bold-salt;
    text-transform: uppercase;
  }

  &__prev,
  &__next,
  &__toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  &__prev,
  &__next {
    width: 30px;
    height: 30px;
  }
  &__prev {
    transform: rotate(-180deg);
  }
  &__toggle {
    margin-left: 10px;
  }
  .rotated-calendar-toggler {
    transform: rotate(180deg);
  }
}
