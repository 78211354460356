@import "./utils/font-face/index.scss";
@import "./assets/styles/newColors.scss";
@import "./assets/styles/mediaSizes.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f0f2f5;
  margin: 0;
}

* {
  // font-family: greycliff-cf;
  font-family: "Inter", sans-serif;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #00000033 transparent;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000033;
  border-radius: 10px;
  border: 3px solid transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #00000033 transparent;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: scrollbar;
}

*::-ms-scrollbar-thumb {
  background-color: #00000033;
  border-radius: 10px;
}

.app {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;

  & > div:only-child {
    grid-column: 1 / -1;
  }

  @media (max-width: $MOBILE) {
    grid-template-columns: 1fr;
  }
}

.page {
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  width: 80vw;
  flex: 1;
  min-height: 100vh;

  &__title {
    font-family: "greycliffcf-bold-salt";
    font-size: 20px;
    line-height: 21px;
    letter-spacing: -0.4px;
    color: #222222;
    margin-top: 0;
    margin-bottom: 13px;
    padding-left: 13px;
  }

  &__wrapper {
    min-height: 100vh;
    position: relative;
    display: flex;
  }
  &__expandedPeople {
    // padding-left: 20px;
    transition: all 0.5s;
  }
}

.logout_btn {
  color: "white";
  width: 150px;
  height: 40px;
  border-radius: 1.75rem;
  border-color: white;
  background: white;
  padding: 0.5rem;
  margin: 1rem;
  display: block;
  align-self: flex-end;
  bottom: 0;
  position: fixed;
  right: 0;
}

.left {
  width: 30%;
}

.right {
  width: 65%;
  // right: 0;
  // top: 0;
  // position: fixed;
}

.right-full-witdh {
  width: 95%;
}

.message-link {
  color: #4a8cff;
}

.message-mention {
  color: rgb(244, 40, 98);
  text-decoration: none;
  font-family: "greycliffcf-medium-salt";
}

.ifmapr {
  box-shadow: -1px 0px 5px #333 inset !important;
}

.ReactModal__Overlay--after-open {
  z-index: 5;
}

a {
  text-decoration: none;
  color: inherit;
}

.calendar_btn {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 14px 20px;
  border: hidden;
  border-bottom: 1px solid rgb(240, 240, 240);
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
}

.primary_text {
  font-family: "greycliffcf-demibold-salt";
  color: rgb(34, 34, 34);
  font-size: 17px;
}
.calendar_email {
  font-size: 17px;
}
.secondary_text {
  font-family: "greycliffcf-medium-salt";
  color: rgb(157, 157, 157);
  font-size: 13px;
}

*:focus {
  outline: none;
}

.settings-title {
  font-family: "greycliff-cf-bold";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.4px;
  color: #222222;
  margin-top: 0;
  margin-bottom: 12px;
  display: inline-block;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.clickable-row {
  display: flex;
  align-items: center;
  padding: 12px 9px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #f0f2f5;
    border-radius: 14px 0 0 14px;
  }
}
.network-detector {
  &__wrapper {
    position: fixed;
    right: 40px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 80px;
    background-color: #fff;
    animation-name: network;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    transition: transform 2s;
  }

  &__text {
    font-family: greycliffcf-medium-salt;
    font-size: 14px;
    font-weight: 500;
    color: #f42862;
  }
}

.pink-btn {
  background-color: #f53263;
  box-sizing: border-box;
  line-height: 48px;
  color: white;
  text-align: center;
  border-radius: 6px;
  width: 100%;
  font-family: "greycliffcf-bold-salt";
  font-size: 18px;
  cursor: pointer;
  border: none;
  &--disabled {
    opacity: 0.5;
    cursor: initial;
  }
}

.btn--demibold {
  font-family: "greycliffcf-demibold-salt";
}
.btn--rounded {
  border-radius: 24px;
}

@keyframes network {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.versionNumber {
  padding-bottom: 20px;
}