@import "assets/styles/mediaSizes.scss";

.suggestion-where-platform-container {
  margin-top: 16px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 5px;

  @media (max-width: $TABLET_L) {
    justify-content: center;
  }
}
