.group-info-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .group-info-container-2 {
    position: absolute;
    width: 100%;
    // z-index: 200;

    .group-info {
      box-sizing: border-box;
      position: relative;
      height: 200px !important;
      padding: 10px 10px 0 16px;

      &:first-child {
        border-top: 1px solid #d4d4d4;
        background-color: #d4d4d41f;
        border-bottom: 1px solid #d4d4d4;
      }
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      //   background-color: transparent;
    }
  }
}
