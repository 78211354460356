.people-your-status {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 16px;
    font-weight: 700;
  }
  :global {
    .MuiOutlinedInput-root.Mui-focused {
      border: 0 !important;
    }
  }
}

.people-status-select {
  height: 36px;
  border-radius: 54px !important;
}

.people-status-menu-item {
  width: 100%;
  display: flex !important;
  align-items: center;
  gap: 4px;

  span {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray5);
    // padding-bottom: 2px;
  }
}

.menu-item-invisible {
  display: none !important;
}
