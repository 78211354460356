.suggestion-duration {
  &-picker {
    display: flex;
    position: absolute;
    // position: fixed;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
    z-index: 100;
    padding: 6px 13px 6px 11px;
    border-radius: 24px;
    cursor: pointer;
  }

  &-title {
    font-family: "greycliff-cf-medium";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    text-align: center;
    color: #f42862;
  }

  &-image {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    align-items: center;
  }
}

.zones-container {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.zonesContainer {
  width: 380px;
  height: 100%;
  overflow: scroll;
  background-color: #f0f2f5;
  // background-color: #f0f0f0;
  box-shadow: inset 0 11px 6px -10px rgba(0, 0, 0, 0.2);
  flex: 1;
  position: relative;
  &.--custom {
    width: 100%;
  }
}

.zonesContainer::-webkit-scrollbar {
  width: 0;
}

.infinite-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
