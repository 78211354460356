@import "assets/styles/mediaSizes.scss";

.suggest-when-modal {
  max-width: 840px;
  height: 85vh;
  margin: 0 -16px;
  background-color: var(--lightGray);
  overflow: auto;
  max-height: 100vh;

  @media (max-width: $MOBILE) {
    overflow-x: hidden;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
}
