.preview-reaction-buttons {
  position: absolute;
  bottom: -20px;
  right: 0;
  display: flex;
  gap: 4px;

  &__button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    border-radius: 54px;
    background-color: #ffffff;
    border: 1px solid var(--lightGray5);
    font-size: 14px;
    font-weight: 600;
    color: var(--gray4);
    cursor: pointer;
  }
}
