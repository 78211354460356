.suggestion-where-platform {
  padding: 8px 12px;
  box-sizing: border-box;
  width: 159px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: background-color 0.25s ease;

  & > div {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 9999px;
    padding: 16px;
    background-color: var(--lightGray6);

    img {
      width: 24px;
      height: 24px;
    }
  }

  & > span {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray4);
  }

  &:hover {
    background-color: var(--lightGray);
  }
}
