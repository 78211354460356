.slack-connection {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  overflow-y: hidden;
  z-index: 999;

  &::-webkit-scrollbar {
    display: none;
  }

  &__icon {
    height: 70px;
  }

  &__title {
    margin-top: 30px;
    font-family: "greycliffcf-demibold-salt";
    font-size: 22px;
    color: #000;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 35px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px 31px;

    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    letter-spacing: -0.34px;
    color: #f42862;

    border: solid 2px #f42862;
    background-color: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.connect {
  margin-left: 35px;
  color: #fff;
  border: solid 2px #f42862;
  background-color: #f42862;
}
