.user-info-popover {
  padding: 16px;
  display: flex;
  align-items: center;

  &__menu-icon {
    transform: scale(1.5);
  }

  .user-info-popover__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  
  &__nickname {
    color: #737373;
    font-size: 14px;
    font-weight: 500;
  }

  &__logout {
    padding: 16px;
    border-top: 1px solid #E5E5E5;
    cursor: pointer;

    &_action {
      color: var(--gray6);
      font-weight: 500;
      line-height: 20px;
      font-family: "Inter";
      font-size: 14px;
    }

    &:hover &_action {
      color: var(--gray4);
    }
  }
}