.people-timezone-container {
  display: flex;
  margin-bottom: 16px;
}

.people-timezone {
  display: flex;
  & > div {
    width: 16px;
    & > div {
      border-radius: 9999px;
      border: 1px solid #fff;
    }
  }
}

.people-timezone-info-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 26px;

  & > div:last-child {
    display: flex;
    gap: 8px;

    & > div:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.people-timezone-time {
  text-align: right;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 13px;
    color: var(--gray4);
  }
}

.people-timezone-info {
  & > div:first-child {
    font-size: 13px;
    font-weight: 500;
    color: var(--gray4);
  }
  & > div:last-child {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  img {
    width: 20px;
    // height: 16px;
  }
}
