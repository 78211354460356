.suggestion-where-your-suggestions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid var(--lightGray5);
  border-bottom: 0;
  padding: 16px 16px;
  box-shadow: 0px -2px 7px -1px #a8aaad24;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 200;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  & > div:last-child {
    border-top: 1px solid var(--lightGray5);
    padding-top: 24px;
    display: flex;
    gap: 8px;

    button {
      height: 44px;
      width: 100%;

      div {
        width: 20px;
        height: 20px;
      }
    }
  }

  ul {
    display: flex;
    gap: 8px;
    list-style: none;
    overflow: auto;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      box-sizing: border-box;
      max-width: 270px !important;
      height: 40px;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;

        & > span {
          font-weight: 400;
        }
      }
    }
  }
}
