.meeting-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--lightGray5);
    }
    h5 {
      font-size: 18px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      word-wrap: break-word;
    }

    button {
      img {
        width: 15px;
        height: 15px;
        margin-left: 8px;
      }
    }

    .link-box {
      border: 1px solid var(--lightGray5);
      border-radius: 4px;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 8px;
      p {
        font-size: 14px;
        font-weight: 500;
        word-wrap: break-word;
      }

      img {
        cursor: pointer;
      }
    }

    .secondary_text {
      color: var(--gray8);
      font-size: 16px;
      font-weight: 400;
    }
  }
}
