.suggest-online__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 223px;
  height: 40px;

  border-radius: 24px;
  border: solid 2px #f42862;
  background-color: #fff;

  font-family: "greycliffcf-demibold-salt";
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.34px;
  text-align: left;
  color: #f42862;
  box-sizing: border-box;
  cursor: pointer;
}

.suggest-online__suggest {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  margin-left: 8px;

  font-family: "greycliffcf-demibold-salt";
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.34px;
  text-align: center;
  color: #fff;

  border-radius: 24px;
  background-color: #f42862;

  cursor: pointer;
}

.inactive {
  background-color: #f0f2f5;
  color: #a9b0b9;
  cursor: not-allowed;
}
