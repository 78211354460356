// TimqzonesTimePicker
.timezones-time-picker {
  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    z-index: 1000;

    &.--custom {
      z-index: 2001;
    }
  }

  &__container {
    width: 484px;
    height: 632px;
    // padding: 12px 0 0;
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
    background-color: #f0f2f5;
    // background-color: #f0f0f0;
    position: absolute;
    right: 20px;
  }
}

// FinalDateAndTime

.final-date-time {
  &__wrapper {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 460px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  }

  &__content {
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    color: #212121;
  }
}

//TimePickerContainer

.time-picker {
  &__wrapper {
    width: 100%;
    height: 568px;
    background-color: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
  }
}

//StartTimeContainer

.start-time {
  &__container {
    box-sizing: border-box;
    padding: 17px 12px 7px 12px;
  }

  &__title {
    height: 20px;
    font-family: "greycliffcf-extrabold-salt";
    font-size: 17px;
    font-weight: 800;
    letter-spacing: -0.34px;
    color: #222222;
    padding-bottom: 16px;
  }

  &__time {
    font-weight: 500;
    font-family: "greycliff-cf-medium";
  }

  &__picker-wrapper {
    display: flex;
  }
}

//TimePickerItem

.time-picker-item {
  &__container {
    height: 172px;
    border-radius: 8px;
    background-color: #f0f2f5;
    // background-color: #f0f0f0;
    box-sizing: border-box;
    padding: 8px;
  }

  &__title {
    font-family: "greycliff-cf-bold";
    font-size: 10px;
    font-weight: bold;
    color: #9d9d9d;
    text-align: center;
    text-transform: uppercase;
  }

  &__container:not(:first-child) {
    margin-left: 8px;
  }

  &__zero {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "greycliffcf-demibold-salt";
    border-radius: 22px;
    margin-bottom: 3px;
    margin-top: 8px;
  }

  &__elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__no-duration {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__no-duration--text {
    width: 148px;
    height: 28px;
    border-radius: 22px;
    background-color: #ffffff;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//Item

.item {
  &__container {
    box-sizing: border-box;
    width: 40px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-top: 8px;
    border-radius: 22px;
    cursor: pointer;
  }

  &__container:nth-child(6n + 1) {
    margin-left: 0px;
  }

  &__number {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    font-weight: 600;
    color: #222222;
    letter-spacing: -0.3px;
  }

  &__day-part {
    font-size: 10px;
    margin-left: 2px;
  }
}

//TimePickerFooter

.time-picker-footer {
  &__container {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    margin-top: 22px;

    .pink--button {
      background-color: #f42862;
      color: #ffffff;
      margin-left: 8px;
    }
  }

  &__button {
    width: 157px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 24px;
    border: solid 2px #f42862;
    background-color: #ffffff;
    outline: none;
    font-family: "greycliffcf-demibold-salt";
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }
}
