.contact {
  &__notation {
    color: #919191;
    font-size: 14px;
    line-height: 17px;
    font-family: "greycliffcf-medium-salt";
    display: block;
    margin-bottom: 20px;
  }

  &__item {
    height: 76px;
    border-radius: 12px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e6e6e6;
    background-color: #f5f8fa;
    // background-color: #f5f5f5;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 20px;
    display: flex;
    align-items: center;

    &:nth-last-of-type(n-1) {
      margin-bottom: 20px;
    }
  }

  &__type {
    font-size: 12px;
    line-height: 14px;
    font-family: "greycliffcf-demibold-salt";
    color: #00000099;
    display: block;
    margin-bottom: 2px;
    text-transform: capitalize;
  }

  &__value {
    font-size: 18px;
    line-height: 21px;
    font-family: "greycliffcf-medium-salt";
    color: #000000;
  }

  &__add {
    color: #f42c62;
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
    display: block;
    margin-bottom: 50px;
  }

  &__edit {
    width: 18px;
    height: 18px;
    fill: #f53263;
    cursor: pointer;
    display: none;

    &--wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.delete {
  &__title {
    color: #000000;
    font-size: 17px;
    line-height: 22px;
    font-family: "greycliffcf-bold-salt";
    display: block;
    margin: auto;
    width: max-content;
  }

  &__text {
    color: #000000;
    font-size: 13px;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 22px;
    text-align: center;
    font-family: "greycliffcf-medium-salt";
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;

    span {
      font-size: 17px;
      line-height: 22px;
      color: #f42862;
      font-family: "greycliffcf-demibold-salt";
      cursor: pointer;

      &:last-child {
        font-family: "greycliffcf-bold-salt";
      }
    }
  }
}

.learn_more_modal {
  &__close_icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
  }

  &__logo {
    width: 30px;
    height: 30px;
  }

  &__title {
    width: 290px;
    height: 56px;
    font-family: "greycliffcf-regular-salt";
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin: 20px;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    color: "red";
  }

  &__subtitle {
    width: 278px;
    height: 19px;
    font-family: "greycliffcf-regular-salt";
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #454c52;
  }

  &__underline {
    color: #dbdbdb;
    background-color: #dbdbdb;
    border-color: #dbdbdb;
    width: 100%;
    opacity: 0.5;
  }

  &__faq_title {
    width: 528px;
    height: 23px;
    opacity: 0.8;
    font-family: "greycliffcf-regular-salt";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  &__faq_desc {
    height: 36px;
    opacity: 0.8;
    font-family: "greycliffcf-regular-salt";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  &__additional_support {
    height: 21px;
    font-family: "greycliffcf-regular-salt";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    align-self: flex-start;
    margin-top: 20px;
    &--link {
      color: #f42862;
    }
  }
}
