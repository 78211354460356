.participants-modal {
  width: 400px;

  &__participants {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1px;
    margin-top: 16px;
    max-height: 400px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;

    &__name {
      font-weight: 500;
    }
  }
}
