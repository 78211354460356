.select-time__options {
  display: flex;
  margin-bottom: 20px;
}

.select-time__for-others {
  background-color: #f0f2f5;
  border-radius: 8px;
  padding: 11px 16px 17px 16px;
  margin-bottom: 23px;

  .select-time__for-others__header {
    display: flex;
    align-items: center;
    color: #8995a4;
    font-family: "greycliffcf-bold-salt";
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.24px;
    text-align: left;
    color: #8995a4;
    text-transform: uppercase;
    margin-bottom: 13px;

    span {
      text-transform: initial;
      background-color: #ffffff;
      font-family: "greycliffcf-medium-salt";
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: -0.26px;
      color: #8995a4;
      padding: 0 12px 0 8px;
      margin: 0 6px;
      border-radius: 16px;
      height: 24px;
    }

    .select-time__for-others__header__globe-icon > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
    }
  }
}

// ====
.select-time__option-button {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  border: 1px solid #e3e5e8;
  border-radius: 12px;
  height: 52px;
  width: 134px;
  padding: 0 17px 0 12px;
  margin-left: 8px;
  cursor: pointer;

  &:first-child {
    margin: 0;
  }

  &:hover {
    border-color: #595f82;
  }

  span {
    &:first-child {
      font-family: "greycliffcf-medium-salt";
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.3px;
      text-align: left;
      color: #595f82;
    }

    &:last-child {
      opacity: 0.5;
      font-family: "greycliffcf-medium-salt";
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: -0.24px;
      text-align: left;
      color: #595f82;
    }
  }

  &.selected {
    background-color: #595f82;
    border-color: #595f82;
    span {
      &:first-child {
        color: #ffffff;
      }
      &:last-child {
        color: #ffffff;
      }
    }
  }
}

// ==== time by time zone ====
.time-by-time-zone {
  border-top: 1px solid #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  position: relative;

  .time-by-time-zone__date-time {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 36px;
    padding: 2px 0;
    box-sizing: border-box;

    &:last-child {
      position: absolute;
      right: 0;
      span {
        text-align: right;
      }
    }

    span {
      font-stretch: normal;
      font-style: normal;
      &:first-child {
        font-family: "greycliffcf-regular-salt";
        font-size: 12px;
        font-weight: 500;
        line-height: 0.83;
        letter-spacing: -0.24px;
        color: #a9b0b9;
      }

      &:last-child {
        font-family: "greycliffcf-bold-salt";
        font-size: 15px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: -0.3px;
        color: #8995a4;
      }
    }
  }

  .time-by-time-zone__avatars {
    position: relative;
    height: 36px;
    .time-by-time-zone__person {
      // width: 32px;
      // height: 32px;
      // border-radius: 50%;
      // border: 2px solid #ffffff;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .time-by-time-zone__person-shadow {
        position: absolute;
        z-index: 5;
        width: 32px;
        height: 32px;
        max-width: 32px;
        max-height: 32px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: end;
        align-items: center;
        // opacity: 0.5;
        padding-right: 4px;
        box-sizing: border-box;
        font-family: "greycliffcf-medium-salt";
        font-size: 11px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: -0.22px;
      }
    }
  }
}

.select-time__option-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border-color: #e3e5e8;

  &:hover {
    border-color: #e3e5e8;
  }
}
