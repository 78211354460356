.suggestion-popup__timezones {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 500px;
  margin: -24px;
  overflow-y: auto;
  max-height: 45vh;

  .time-picker-container {
    margin-bottom: 0 !important;
  }
}
