@font-face {
  font-family: "greycliffcf-bold-salt";
  src: url("../../assets/fonts/greycliffcf-bold-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-demibold-salt";
  src: url("../../assets/fonts/greycliffcf-demibold-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-extrabold-salt";
  src: url("../../assets/fonts/greycliffcf-extrabold-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-heavy-salt";
  src: url("../../assets/fonts/greycliffcf-heavy-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-light-salt";
  src: url("../../assets/fonts/greycliffcf-light-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-medium-salt";
  src: url("../../assets/fonts/greycliffcf-medium-salt.ttf");
}

@font-face {
  font-family: "greycliffcf-regular-salt";
  src: url("../../assets/fonts/greycliffcf-regular-salt.ttf");
}

@font-face {
  font-family: "greycliff-cf-medium";
  src: url("../../assets/fonts/greycliff-cf-medium.ttf");
}

@font-face {
  font-family: "greycliff-cf-bold";
  src: url("../../assets/fonts/greycliff-cf-bold.ttf");
}

@font-face {
  font-family: "greycliff-cf";
  font-weight: 100;
  src: url("../../assets/fonts/greycliff-cf-thin.ttf");
}

@font-face {
  font-family: "greycliff-cf";
  font-weight: 400;
  src: url("../../assets/fonts/greycliff-cf-regular.ttf");
}

@font-face {
  font-family: "greycliff-cf";
  font-weight: 600;
  src: url("../../assets/fonts/greycliff-cf-medium.ttf");
}

@font-face {
  font-family: "greycliff-cf";
  font-weight: 700;
  src: url("../../assets/fonts/greycliff-cf-bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: url("../../assets/fonts/Inter/Inter-Thin.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("../../assets/fonts/Inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("../../assets/fonts/Inter/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("../../assets/fonts/Inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("../../assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: url("../../assets/fonts/Inter/Inter-ExtraBold.ttf");
}
