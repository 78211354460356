.rightside {
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
  width: 370px;

  &__modal {
    margin-left: auto;
    width: 30%;
  }
}

.suggesttoolbar {
  &__none {
    display: none !important;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px;
    width: 40px;
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
    flex-direction: column;
    margin-left: 4px;
    // margin-bottom: 10px;
    margin: auto;

    // &:hover {
    //     border-radius: 50%;
    //     background-color: #f0f0f0;
    // }

    &_boxshadow {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      top: -140px;
      width: 155px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
      padding: 10px 5px;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }

  &__tooltip {
    &_wrapper {
      width: 90%;
    }
    &_triangle {
      z-index: 2;
      position: absolute;
      padding: 10px;
      bottom: -10%;
      width: 5px;
      height: 5px;
      // height: 155px;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      width: 100%;
    }
    &_item > img {
      margin-right: 5px;
    }
    &_item:first-of-type {
      border-bottom: 1px solid #f0f2f5;
      // border-bottom: 1px solid #f0f0f0;
    }
    &_item > span {
      font-family: "greycliffcf-demibold-salt";
      font-size: 15px;
      line-height: 1.47;
      letter-spacing: -0.3px;
      text-align: left;
      color: #222222;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__content > span {
    font-family: "greycliffcf-demibold-salt";
    font-size: 15px;
    line-height: 1.47;
    letter-spacing: -0.3px;
    text-align: left;
    color: #222222;
  }
}

.content__wrapper {
  padding: 6px;
  border-radius: 50%;
}

.suggesttoolbar__wrapper:hover .content__wrapper {
  background-color: #f5f8fa;
  // background-color: #f5f5f5;
}
