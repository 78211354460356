.suggest-modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89px;
  border-top: 1px solid #e3e5e8;
  display: flex;
  justify-content: center;
  align-items: center;
}
