.conversation-bottom {
  min-height: 56px;
  // padding: 0 16px;
  box-sizing: border-box;
  border-top: 1px solid #e1e1e1;
  box-shadow: 0 -3px 8px 1px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__plus_image {
    width: 20px;
    height: 20px;
  }
  img {
    cursor: pointer;
  }
}

.comments-input {
  position: relative;
  min-height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__plus_image {
    width: 20px;
    height: 20px;
  }
  img {
    cursor: pointer;
  }
}
