.time-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
}

.time-line-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-right: 8px;

  &__line {
    margin-left: 7px;
    width: 2px;
    height: 54px;
    align-self: baseline;
    background-color: black;
    transition: all 0.2s ease-in-out;

    &__first {
      height: 28px;
    }

    &__future {
      background-color: var(--lightGray5);
    }

    &__next {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  &__info__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    border: 1px solid var(--lightGray5);
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &__disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &__dot {
      box-sizing: border-box;
      width: 5px;
      height: 5px;
      border-radius: 9999px;
      background-color: var(--black);
    }

    &__check {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
      }
    }

    &__passed {
      border: 1px solid black;
      background-color: black;
    }

    &__next {
      border: 2px solid black;
    }
  }

  &__label {
    margin-left: 25px;
    font-size: 12px;
    font-weight: 600;
    color: var(--black);

    &__selected {
      border-radius: 4px;
      padding: 2px 8px;
    }

    &__disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
