@import "assets/styles/mediaSizes.scss";

.people-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: $MOBILE) {
    padding: 0;
  }
}
