.participants-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  &:not(:last-child) {
    margin-bottom: 74px;
  }
  &:first-child {
    margin-top: 15px;
    margin-bottom: 145px;
  }
  &--left,
  &--right {
    display: flex;
  }
  &--right {
    flex-direction: column;
    align-items: flex-end;
  }
  &__date,
  &__time {
    font-family: greycliffcf-demibold-salt;
  }
  &__date {
    color: rgb(127, 127, 127);
    font-size: 12px;
  }
  &__time {
    color: #000;
    font-size: 15px;
  }
  &__avatars {
    display: flex;
    align-items: center;
    &-img {
      width: 32px;
      height: 32px;
      border: 2px solid #fff;
      border-radius: 50%;
      object-fit: cover;
      &:not(:first-child) {
        margin-left: -15px;
      }
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    &-title,
    &-timezone {
      font-family: greycliffcf-demibold-salt;
    }
    &-title {
      font-size: 12px;
      color: rgb(127, 127, 127);
    }
    &-timezone {
      color: #000;
      font-size: 14px;
    }
  }
}
