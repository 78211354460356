.suggestion-item__header {
  &__status {
    border: 1px solid var(--lightGray5);
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 600;
    &__success {
      background-color: #f0fdf4;
      color: #15803d;
      border: 1px solid #baedcd;
      font-size: 12px;
      padding: 2px 6px;
    }

    &__error {
      font-size: 12px;
      padding: 2px 6px;
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 600;
      border: 1px solid var(--red4);
      color: var(--red6);
      background-color: var(--red5);
      border-radius: 4px;
    }
  }
}
