@import "assets/styles/mediaSizes.scss";

.people-block {
  position: relative;
  border: 1px solid var(--lightGray5);
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;

  @media (max-width: $MOBILE) {
    border-radius: 0;
  }

  &:first-child {
    @media (max-width: $MOBILE) {
      margin-top: 10px;
    }
  }
}
