.general-time__date {
  display: flex;
  margin-left: 24px;
  margin-top: 16px;

  .general-time__date-divider {
    width: 1px;
    background-color: #e3e5e8;
    margin-top: 39px;
  }

  .general-time__calendar {
    flex-grow: 1;
  }

  .general-time__quick-select {
    width: 234px;
    max-width: 234px;
    padding-left: 50px;
    box-sizing: border-box;
  }
}

.general-time__time {
  margin: 15px 27px 0 24px;
  padding-top: 20px;
  border-top: 1px solid #e3e5e8;
}

.general-time__sub-title {
  margin: 0 0px 16px 0;
  font-family: "greycliffcf-bold-salt";
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.3px;
  text-align: left;
  color: #222;
  white-space: nowrap;

  span {
    margin: 3px 713px 17px 9px;
    font-size: 12px;
    line-height: 2.08;
    letter-spacing: -0.24px;
    color: #a9b0b9;
    text-transform: uppercase;
  }
}

.general-time-tab {
  &__arrows {
    position: relative;
  }
  &__content {
    box-sizing: content-box;
    min-width: 898px;
    height: 92px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #f0f2f5;
    &::-webkit-scrollbar {
      height: 8px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: none;
      border-radius: 5px;
      transition: background-color 0.2s ease;
    }
  
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  
  }
  &__arrow {
    position: absolute;
    top: 30px;
    height: 32px;
    width: 32px;
    background-color: #fff;
    border: 1px solid #e3e5e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border-color: #595f82;
    }

    path {
      fill: #595f82 !important;
    }

    &--left {
      left: 5px;
    }

    &--right {
      right: 5px;
    }
  }
}
