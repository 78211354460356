.people-title-content-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  color: var(--gray4);

  & > div:last-child {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
