.empty-conversation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: auto 0;

  h4 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: var(--gray5);
    max-width: 350px;
    text-align: center;
  }
}
