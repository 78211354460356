.extraClass {
  font-size: 20px !important;
  pointer-events: auto !important;
  border-radius: 8px;
  background-color: #ffffff;
  opacity: 1;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.tooltip_container {
  display: flex;
  flex-direction: column;
  // margin: 10px 0;
  background-color: #ffffff;
}

#message-tooltip {
  padding: 5px 0;
  // padding: 5px 20px;
  display: flex;
  border-radius: 8px;
  opacity: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
}

.tooltip_list_item {
  // margin: 8px 5px;
  height: 36px;
  padding: 0 20px;
  display: flex;
  // flex-direction: row;
  cursor: pointer;
  align-items: center;
  margin: 0;

  &:active {
    opacity: 0.5;
  }

  &__title {
    margin-left: 10px;
    font-family: "greycliff-cf-medium";
    font-size: 16px;
    color: #222222;
  }
}

.tooltip_list_item:hover {
  background-color: #f5f8fa;
  // background-color: #f5f5f5;
}

.tooltip-icon {
  width: 16px;
  height: 16px;
}

.three-dots {
  // width: 20px;
  // height: 20px;
  position: absolute;
  top: -5px;
  width: 28pt;
  height: 28pt;
  border-radius: 50%;
  margin: -5px -15px;
  z-index: 1;
  object-fit: contain;
}

.three-dots-teeup {
  position: absolute;
  right: 0;
  top: 0;
  width: 28pt;
  height: 28pt;
  border-radius: 50%;
  margin: -15px -10px;
  z-index: 1;
  object-fit: contain;
  cursor: pointer;
}

.three-dots-list {
  position: absolute;
  right: -5px;
  top: -10px;
  width: 28pt;
  height: 28pt;
  border-radius: 50%;
  margin: -8px -5px;
  z-index: 1;
  object-fit: contain;
}

.three-dots-list-suggestions {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 28pt;
  height: 28pt;
  border-radius: 50%;
  margin: -12px -3px;
  z-index: 1;
  object-fit: contain;
}

.three-dots-suggestions {
  // width: 28px;
  // height: 45px;
  width: 28pt;
  height: 28pt;
  position: absolute;
  top: 30%;
  right: 1%;
  z-index: 1;
}
