.tab-bar {
  height: 44px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  // max-width: calc(100vw - 2 * 41px);

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .tab {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    flex-grow: 1;
    flex-shrink: 1;
    width: fit-content;

    &:first-child {
      margin-left: 0;
    }

    span {
      margin: 0 0 0 4px;
      font-family: "Inter";
      font-size: 13px;
      font-weight: 700;
      color: var(--gray);
    }

    div > div {
      display: flex;
      justify-content: center;
      align-items: center;
      path {
        fill: #595f82 !important;
      }
    }

    &.active {
      border-bottom: 2px solid var(--gray6);

      span {
        color: var(--gray6);
      }

      div > div {
        path {
          fill: #222222 !important;
        }
      }
    }
  }
}

.red-dot {
  position: absolute;
  top: 42%;
  right: -12px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.537);
}
