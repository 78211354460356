.desert__container {
  width: 640px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -10px;
}

.desert__wrapper {
  width: 100%;
}

.boomerang {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%) rotate(25deg);
  z-index: 100;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: translateX(-50%) rotate(25deg);
  }
  100% {
    transform: translateX(-50%) rotate(385deg);
  }
}
