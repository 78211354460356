.create-teeup-confirm-modal-content {
  width: 500px;
  padding: 8px;

  h4 {
    margin: 24px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}

.create-teeup-confirm-modal-footer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  button {
    width: 100%;
  }
}
