.suggest-modal__content-when {
  height: 500px;
  padding: 2px 0;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: none;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media (min-height: 670px) {
  .suggest-modal__content-when {
    height: 600px;
    &>div {
      max-height: 100vh !important;
    }
  }
}

@media (max-height: 669px) {
  .suggest-modal__content-when>div {
    max-height: 100vh !important;
  }
}
