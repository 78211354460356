.suggestion-where-meeting-details {
  background-color: #fff;
  padding-top: 16px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      cursor: pointer;
      transform: rotate(90deg);
      width: 16px;
      height: 16px;
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .suggest-where-search {
    width: 100%;
    input {
      padding: 8px 12px;
    }
  }
  .select-url-address {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--lightGray5);
    display: flex;
    flex-direction: column;
    gap: 8px;

    div.custom-input {
      margin-top: 8px;
    }

    .select-url-address-container {
      display: flex;
      gap: 8px;
    }
  }

  .custom-textarea {
    width: 97%;
  }
}

.additional-information {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  h5 {
    font-size: 14px;
    font-weight: 600;
  }
}

.custom-textarea {
  min-height: 150px;
  margin-top: 16px;
  // width: 97%;
  border-radius: 8px;
  border: 1px solid var(--gray);
  padding: 8px 12px;
  font-size: 14px;
  resize: vertical;
  // resize: none;
}
